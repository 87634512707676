import React, { useCallback } from 'react';
import { Card, CardContent } from './ui/card';
import { motion } from 'framer-motion';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import logo from './assets/tsiws-logo.png';

const ParticleBackground = () => {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  return (
    <Particles
      className="absolute inset-0"
      init={particlesInit}
      options={{
        particles: {
          number: { value: 40, density: { enable: true, value_area: 800 } },
          color: { value: '#ffffff' },
          shape: { type: 'circle' },
          opacity: { value: 0.3, random: true, anim: { enable: true, speed: 1, opacity_min: 0.1, sync: false } },
          size: { value: 3, random: true, anim: { enable: false, speed: 40, size_min: 0.1, sync: false } },
          line_linked: { enable: true, distance: 150, color: '#ffffff', opacity: 0.2, width: 1 },
          move: { enable: true, speed: 2, direction: 'none', random: false, straight: false, out_mode: 'out', bounce: false, attract: { enable: false, rotateX: 600, rotateY: 1200 } }
        },
        interactivity: {
          detect_on: 'canvas',
          events: { onhover: { enable: true, mode: 'repulse' }, onclick: { enable: true, mode: 'push' }, resize: true },
          modes: { grab: { distance: 400, line_linked: { opacity: 1 } }, bubble: { distance: 400, size: 40, duration: 2, opacity: 8, speed: 3 }, repulse: { distance: 200, duration: 0.4 }, push: { particles_nb: 4 }, remove: { particles_nb: 2 } }
        },
        retina_detect: true
      }}
    />
  );
};

function AboutSection() {
  return (
    <section
      className="relative bg-gradient-to-r from-[rgba(255,255,255,0.8)] to-[rgba(4,0,255,0.8)] rounded-lg shadow-2xl overflow-hidden 
                  w-[96%] sm:w-[68%] 
                  mx-[3%] sm:ml-[30%] sm:mr-[1%]
                  mt-[1%] sm:mt-[2%] 
                  mb-[5%] sm:mb-[2%]
                  rounded-t-3xl"
    >
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-4x1 mx-auto"
      >
        <Card className="bg-[rgba(4,0,255,0.3)] backdrop-filter backdrop-blur-lg shadow-2xl border border-white/10 overflow-hidden">
          <CardContent className="p-8 relative">
            <ParticleBackground />
            <div className="relative z-10">
            <div className="bg-white bg-opacity-40 backdrop-filter backdrop-blur-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mt-8 mb-6 py-4 rounded-2xl"
            style={{
                boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)"
            }}>
              <motion.h2 
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className="text-5xl font-bold text-white mb-6 mt-12 text-center text-shadow"
              >
                We are Tsiws
              </motion.h2>
              
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.4, duration: 0.5 }}
                className="flex justify-center mb-6"
              >
                <img
                  src={logo}
                  alt="Tsiws Logo"
                  width={200}
                  height={200}
                  className="mx-auto filter drop-shadow-lg -mt-8 sm:-mt-4"
                />
              </motion.div>
              <motion.h3
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6, duration: 0.5 }}
                className="text-3xl font-semibold text-white mb-4 text-center text-shadow"
              >
                Expect custom technology solutions that meet your business or personal unique needs. <br/><br/>
              </motion.h3>
              </div>
              <Card className="bg-[rgba(255,255,255,0.2)] backdrop-filter backdrop-blur-md shadow-xl hover:shadow-2xl transition-all duration-300 border border-white/20 mb-6">
                <CardContent className="p-6">
                  <motion.h4
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.6, duration: 0.5 }}
                   className="text-lg sm:text-xl md:text-2xl text-gray-200 mb-8 text-center text-shadow"
                  >
                    Based in Jamaica and formally known as Island Website Solutions, Tsiws is an emerging trendsetter at the forefront of the modern technology landscape.
                  </motion.h4>
                </CardContent>
              </Card>

              <Card className="bg-[rgba(255,255,255,0.2)] backdrop-filter backdrop-blur-md shadow-xl hover:shadow-2xl transition-all duration-300 border border-white/20 mb-6">
                <CardContent className="p-6">
                  <motion.h5
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.8, duration: 0.5 }}
                    className="text-lg sm:text-xl md:text-2xl text-gray-200 mb-8 text-center text-shadow"
                  >
                    Our vision and innovation, our advanced capability and flexibility are the things that set us apart in our field as an AI First technology solutions provider.
                  </motion.h5>
                </CardContent>
              </Card>

              <Card className="bg-[rgba(255,255,255,0.2)] backdrop-filter backdrop-blur-md shadow-xl hover:shadow-2xl transition-all duration-300 border border-white/20">
                <CardContent className="p-6">
                  <motion.h6
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 1, duration: 0.5 }}
                    className="text-sm sm:text-base md:text-lg text-gray-200 text-justify text-shadow"
                    >
                    At Tsiws, we are dedicated to driving innovation and excellence in technology solutions. Our commitment to integrating advanced AI capabilities into diverse industries ensures that your business remains at the forefront of technological advancements. Partner with us to transform your operations, enhance productivity, and unlock new potentials. Explore our wide range of services, from smart website development to AI avatar creation, and let us help you achieve your digital aspirations.
                  </motion.h6>
                </CardContent>
              </Card>
       
            </div>
          </CardContent>
        </Card>
      </motion.div>
    </section>
  );
}

export default AboutSection;