// src/components/ui/input.js
import React from 'react';

export const Input = React.forwardRef(({ className, ...props }, ref) => {
  return (
    <input
      ref={ref}
      className={`border rounded-md p-2 ${className}`}
      {...props}
    />
  );
});

// Add PropTypes for validation (optional)
Input.displayName = 'Input';
