import React, { useCallback, useEffect } from 'react';
import { Card, CardContent } from "./ui/card";
import Particles from "react-tsparticles";
import { Globe, Code, Briefcase, Brain, Cog, Bot, Smartphone, Mic } from 'lucide-react';
import { motion } from 'framer-motion';
import { loadFull } from "tsparticles";
import { Link } from 'react-router-dom';

const ParticleBackground = ({ color }) => {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  return (
    <Particles
      className="absolute inset-0"
      init={particlesInit}
      options={{
        particles: {
          number: { value: 15, density: { enable: true, value_area: 800 } },
          color: { value: color },
          shape: { type: "circle" },
          opacity: { value: 0.5, random: true, anim: { enable: true, speed: 1, opacity_min: 0.1, sync: false } },
          size: { value: 3, random: true, anim: { enable: false, speed: 40, size_min: 0.1, sync: false } },
          line_linked: { enable: true, distance: 150, color: color, opacity: 0.4, width: 1 },
          move: { enable: true, speed: 2, direction: "none", random: false, straight: false, out_mode: "out", bounce: false, attract: { enable: false, rotateX: 600, rotateY: 1200 } }
        },
        interactivity: {
          detect_on: "canvas",
          events: { onhover: { enable: true, mode: "repulse" }, onclick: { enable: true, mode: "push" }, resize: true },
          modes: { grab: { distance: 400, line_linked: { opacity: 1 } }, bubble: { distance: 400, size: 40, duration: 2, opacity: 8, speed: 3 }, repulse: { distance: 200, duration: 0.4 }, push: { particles_nb: 4 }, remove: { particles_nb: 2 } }
        },
        retina_detect: true
      }}
    />
  );
};

const ServiceCard = ({
  icon: Icon,
  title,
  subtitle1,
  subtitle2,
  description,
  buttonText,
  particleColor,
  link
}) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 300 }}
    className="relative overflow-hidden rounded-xl"
  >
    <Card className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg shadow-custom hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20">
      <CardContent className="p-6 relative z-10">
        <ParticleBackground color={particleColor} />
   
    
       
         <div className="bg-blue/60 backdrop-filter backdrop-blur-lg shadow-custom hover:shadow-2xl transition-all duration-300 border border-white/20 mt-0 mb-6 rounded-2xl px-6 py-8">
            
         <div className="relative z-20 text-center text-xl sm:text-5xl md:text-4xl lg:text-5xl font-extrabold mb-3 sm:mb-8 text-[#0400ff] 
              bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mt-8 mb-6 py-4 rounded-2xl"
              style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)" }}>
          <Icon className="h-24 w-24 text-[#0400ff] mt-8 mb-8 transform hover:rotate-12 transition-transform duration-300 mx-auto" />
          <h2 className="text-4xl font-bold mb-8 text-[white] text-shadow: 1px 1px 2px #0400ff">{title}</h2>
         </div>
            
            
            <h3 className="text-lg font-semibold mb-1 text-blue-100 text-left text-shadow: 1px 1px 2px #0400ff">{subtitle1}</h3>
            <h3 className="text-lg font-semibold mb-2 text-blue-100 text-left text-shadow: 1px 1px 2px #0400ff">{subtitle2}</h3>
       
          <h4 className="text-md mt-8 mb-4 text-white text-center text-shadow: 1px 1px 2px #0400ff">{description}</h4>
          
          <Link to={link} className="flex justify-center">
            <motion.button
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
              className="bg-transparent border-2 border-[#0400ff] text-[#0400ff] font-bold py-2 px-4 rounded-lg hover:bg-[#0400ff] hover:text-white transition duration-300 ease-in-out text-shadow: 1px 1px 2px #0400ff"
            >
              {buttonText}
            </motion.button>
          </Link>
        </div>
      </CardContent>
    </Card>
  </motion.div>
);

function ServicesSection() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const services = [
    
    {
      icon: Bot,
      title: "AI Agents",
      subtitle1: "Speak with your network without wasting time",
      subtitle2: "The Importance and Relevance of AI Voice Agents in business and in personal life is quickly becoming Apparent.",
      description: "Ready for Your Virtual Assistant?",
      buttonText: "Get Agents",
      particleColor: "#d0021b",
      link: "/ai-agents"
    },
    
    {
      icon: Globe,
      title: "Smart Website Development",
      subtitle1: "Your Business is More than just a Building",
      subtitle2: "Your Website should be More than just an Online Sign",
      description: "Enhance your Online Environment With Advanced Technology",
      buttonText: "Explore",
      particleColor: "#4a90e2",
      link: "/smart-websites"
    },

    {
      icon: Cog,
      title: "AI Task Automation",
      subtitle1: "Now, Businesses are Enhancing Productivity with Smart Automation Solutions",
      subtitle2: "Streamline Your Processes, Maximize Efficiency",
      description: "Automate, Innovate, Elevate Your Business",
      buttonText: "See Examples",
      particleColor: "#bd10e0",
      link: "/task-automation"
    },
  
    {
      icon: Briefcase,
      title: "Technology Consultation",
      subtitle1: "The Conversations that You need To have when Navigating Today's Digital Landscape",
      subtitle2: "You need Expert Advice for Cutting-Edge Solutions",
      description: "Get Strategic Insights for Technological Stability",
      buttonText: "Get More Info",
      particleColor: "#f5a623",
      link: "/consultation"
    },
    {
      icon: Brain,
      title: "AI Systems Integration",
      subtitle1: "Harness the Power of AI to Transform Your Enterprise",
      subtitle2: "You need Seamless AI Integration for Smooth and Smarter Operations",
      description: "You should Integrate AI at Every Practical Level",
      buttonText: "Discover How",
      particleColor: "#7ed321",
      link: "/ai-integration"
    },
   
    {
      icon: Smartphone,
      title: "Mobile App Development",
      subtitle1: "Bring Your Best Visions to Life, One App at a Time",
      subtitle2: "Your Ideas, Our Expertise: Seamless App Development",
      description: "Your Path to Effective Mobile Applications",
      buttonText: "Start Building",
      particleColor: "#9013fe",
      link: "/app-development"
    },
  



    {
      icon: Code,
      title: "API Development",
      subtitle1: "Your Business Needs Seamless Connectivity",
      subtitle2: "We Are Building Modern Bridges Between Applications",
      description: "Unlock New Capabilities with Custom APIs",
      buttonText: "Learn More",
      particleColor: "#50e3c2",
      link: "/api-services"
    }
  ];

  return (
    <section 
      className="py-12 px-2
                 w-full sm:w-[68%] 
                 ml-[2%] mr-[2%] mt-[1%] mb-[5%] 
                 sm:ml-[30%] sm:mr-[1%] sm:mt-[2%] sm:mb-[2%]">
      
      <motion.div 
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }} 
        className="text-center mb-12 mt-4"
      >
        <Card className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg shadow-custom hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20">
          <CardContent className="p-6 relative z-10">
            <h2 className="text-5xl font-bold text-[#0400ff] relative no-underline" style={{ textShadow: '1px 1px 10px white' }}>
              Our range of services
            </h2>
          </CardContent>
        </Card>
      </motion.div>

      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }} 
        className="grid gap-8 md:grid-cols-2"
      >
        {services.map((service, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <ServiceCard {...service} />
          </motion.div>
        ))}
      </motion.div>

    </section>
  );
}

export default ServicesSection;