

import React, { useEffect, useRef } from 'react';

const shapes = [
  // Computer
  [[0, 0], [80, 0], [80, 60], [40, 90], [0, 60]],
  // Smartphone
  [[20, 0], [60, 0], [60, 80], [20, 80], [20, 0], [30, 85], [50, 85]],
  // Cloud
  [[10, 50], [30, 30], [50, 20], [70, 30], [90, 50], [70, 70], [50, 80], [30, 70], [10, 50]],
  // Wi-Fi symbol
  [[0, 60], [20, 40], [40, 60], [10, 50], [30, 30], [50, 50], [20, 40], [40, 20], [60, 40]],
];

function AnimatedParticleConstellationBackground() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const particles = [];
    const particleCount = 100;
    let animationFrameId;
    let currentShape = [];
    let shapeTimer = 0;
    const shapeInterval = 10000; // 10 seconds

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      initParticles();
    };

    const initParticles = () => {
      particles.length = 0;
      for (let i = 0; i < particleCount; i++) {
        particles.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          size: Math.random() * 2 + 1,
          speedX: (Math.random() - 0.5) * 0.5,
          speedY: (Math.random() - 0.5) * 0.5
        });
      }
    };

    const drawParticle = (particle) => {
      if (!ctx) return;
      ctx.beginPath();
      ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
      ctx.fillStyle = 'rgba(100, 200, 255, 0.5)';
      ctx.fill();
    };

    const updateParticle = (particle) => {
      particle.x += particle.speedX;
      particle.y += particle.speedY;

      if (particle.x < 0 || particle.x > canvas.width) particle.speedX *= -1;
      if (particle.y < 0 || particle.y > canvas.height) particle.speedY *= -1;
    };

    const drawLines = () => {
        if (!ctx) return;
        for (let i = 0; i < particles.length; i++) {
          for (let j = i + 1; j < particles.length; j++) {
            const dx = particles[i].x - particles[j].x;
            const dy = particles[i].y - particles[j].y;
            const distance = Math.sqrt(dx * dx + dy * dy);
      
            if (distance < 100) {
              ctx.beginPath();
              ctx.moveTo(particles[i].x, particles[i].y);
              ctx.lineTo(particles[j].x, particles[j].y);
              // Set the line color to white with a fading effect based on distance
              ctx.strokeStyle = `rgba(255, 255, 255, ${1 - distance / 100})`;
              ctx.stroke();
            }
          }
        }
      };
      

    const formShape = () => {
      const shapeIndex = Math.floor(Math.random() * shapes.length);
      currentShape = shapes[shapeIndex];
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;

      for (let i = 0; i < currentShape.length; i++) {
        if (i < particles.length) {
          particles[i].x = centerX + currentShape[i][0];
          particles[i].y = centerY + currentShape[i][1];
          particles[i].speedX = (Math.random() - 0.5) * 0.5;
          particles[i].speedY = (Math.random() - 0.5) * 0.5;
        }
      }
    };

    const animate = (timestamp) => {
      if (!ctx) return;
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      if (timestamp - shapeTimer > shapeInterval) {
        formShape();
        shapeTimer = timestamp;
      }

      particles.forEach(particle => {
        drawParticle(particle);
        updateParticle(particle);
      });

      drawLines();

      animationFrameId = requestAnimationFrame(animate);
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);
    animationFrameId = requestAnimationFrame(animate);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <div className="fixed inset-0 z-[-1] bg-blue-900 overflow-hidden">
      <canvas
        ref={canvasRef}
        className="absolute inset-0 w-full h-full"
      />
    </div>
  );
}

export default AnimatedParticleConstellationBackground;
