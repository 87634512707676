import React from 'react';
import { motion } from 'framer-motion';

function SmartWebsiteInfoCard1() {
  return (
    <>
      <motion.header
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 0.85, y: 0 }}
        transition={{ duration: 0.5 }}
        className="relative bg-gradient-to-r from-white/20 to-[#0400ff30] py-2 px-4 sm:px-6 lg:px-8 rounded-lg shadow-2xl overflow-hidden
                   w-[96%] sm:w-[68%] mx-[2%] sm:ml-[30%] sm:mr-[1%] mt-4 mb-4 rounded-t-3xl 
                   backdrop-filter backdrop-blur-sm flex items-center justify-center text-center"
      >
        <div className="bg-white/30 backdrop-filter backdrop-blur-lg shadow-custom hover:shadow-2xl transition-all duration-300 border border-white/20 mb-6 rounded-2xl px-4 py-2 max-w-[90%] sm:max-w-[80%]">
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 0.5 }}
            className="text-lg sm:text-xl md:text-3xl font-bold text-[#0400ff]"
            style={{ textShadow: '1px 1px 10px white' }}
          >
            Features of a Smart Website
          </motion.p>
        </div>
      </motion.header>
    </>
  );
}

export default SmartWebsiteInfoCard1;
