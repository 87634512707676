import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Card } from './ui/card';

import { Code } from 'lucide-react';
import logo from './assets/tsiws-logo.png'; // Import the logo image
import ApiServicesSidebar from './AiIntegrationSidebar';

const services = [
  {
    title: "Custom API Solutions",
    description: "Tailored APIs to meet your unique business requirements."
  },
  {
    title: "API Integration",
    description: "Seamlessly integrate APIs with existing systems for optimal performance."
  },
  {
    title: "API Consulting",
    description: "Expert advice and guidance on API strategy and implementation."
  }
];

const processSteps = [
  {
    title: "Brainstorming",
    description: "Develop and test your API in an experimental environment."
  },
  {
    title: "API Agreement",
    description: "Define methods and resources in a contract."
  },
  {
    title: "Prototyping",
    description: "Outline the development process before coding begins."
  },
  {
    title: "Development",
    description: "Frequent feedback and milestone tracking ensure timely delivery."
  },
  {
    title: "Validation",
    description: "Extensive testing by our QA team guarantees a reliable product."
  },
  {
    title: "Monitoring & Maintenance",
    description: "Post-deployment support for performance optimization."
  }
];

const techStack = [
  "gRPC", "OData", "Falcor", "JSON-RPC", 
  "XML-RPC", "REST", "SOAP", "GraphQL"
];

function TsiwsAPIServicesCombined() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-opacity-10 backdrop-filter backdrop-blur-sm bg-gradient-to-b from-white to-[#0400ffa8]" style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)" }}>
      <motion.header
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="relative bg-gradient-to-r from-white to-[#0400ffa8] py-20 px-0 sm:px-2 lg:px-6 rounded-lg shadow-2xl overflow-hidden
          w-[96%] sm:w-[68%] 
          mx-[2%] sm:ml-[30%] sm:mr-[1%]
          mt-[1%] sm:mt-[2%] 
          mb-[5%] sm:mb-[2%]
          rounded-t-3xl"
      >
        <div className="relative max-w-6xl mx-auto text-center">
          <motion.img
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            src={logo} // Use the imported logo
            alt="Tsiws Logo"
            width={150}
            height={150}
            className="mx-auto filter drop-shadow-lg -mt-16"
          />

          <div className="bg-white/30 backdrop-filter backdrop-blur-lg shadow-custom hover:shadow-2xl transition-all duration-300 border border-white/20 mt-10 mb-6 rounded-2xl px-6 py-8 flex flex-col items-center">
           

            <motion.p
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ delay: 1, duration: 0.5 }}
  className="text-md sm:text-lg md:text-xl lg:text-2xl mt-5 mb-6 font-bold text-[#0400ff] bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg shadow-custom hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 py-6 px-4 sm:px-6 rounded-2xl mx-auto"
  style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)", maxWidth: "90%" }}
>
  <motion.h1
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: 0.4, duration: 0.5 }}
    className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-extrabold tracking-tight text-[#0400ff] -mt-2 mb-6"
    style={{ textShadow: '1px 1px 10px white' }}
  >
     <div className="flex justify-center mb-10">
              <Code className="text-[#0400ff] w-12 h-12" />
            </div>
    API Development
  </motion.h1>
</motion.p>

              
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6, duration: 0.5 }}
              className="text-xl mb-4 font-bold text-[#0400ff] text-center"
            >
              Seamless connectivity - unlocking exceptional capabilities
            </motion.p>
          </div>
          
          <div className="flex justify-center mt-6">
            <button className="bg-transparent border-2 border-[#0400ff] text-[#0400ff] font-bold py-2 px-4 rounded-lg hover:bg-[#0400ff] hover:text-white transition duration-300 ease-in-out">
              Consult with us
            </button>
          </div>
        </div>
      </motion.header>

      <main className="bg-opacity-10 backdrop-filter backdrop-blur-sm w-[96%] sm:w-[68%] 
        mx-[2%] sm:ml-[30%] sm:mr-[1%]
        mt-[1%] sm:mt-[2%] 
        mb-[5%] sm:mb-[2%]
        rounded-t-3xl">
        <div className="max-w-7xl mx-auto">
          <Card className="bg-blue bg-opacity-20 backdrop-filter backdrop-blur-lg shadow-lg hover:shadow-2xl transition-all duration-300 border border-white-2 border-opacity-20 mb-12">
            <div className="text-center py-8">
              <h2 className="text-4xl font-bold text-[#0400ff] mb-4">Tsiws API Development Services</h2>
              <p className="text-xl text-white">
                Jamaica's premier destination for cutting-edge API development. Revolutionizing the local landscape with advanced technology and superior services.
              </p>
            </div>
          </Card>

          <h3 className="text-3xl font-bold text-[#0400ff] mb-4 text-center">Our Services</h3>
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 mb-12">
            {services.map((service, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2, duration: 0.5 }}
              >
                <Card className="p-6 bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg shadow-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20">
                  <h3 className="text-2xl font-bold text-[#0400ff] mb-2">{service.title}</h3>
                  <p className="text-lg text-white">{service.description}</p>
                </Card>
              </motion.div>
            ))}
          </div>

          <h3 className="text-3xl font-bold text-[#0400ff] mb-4 text-center">Development Process</h3>
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 mb-12">
            {processSteps.map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2, duration: 0.5 }}
              >
                <Card className="p-6 bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg shadow-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20">
                  <h3 className="text-2xl font-bold text-[#0400ff] mb-2">{step.title}</h3>
                  <p className="text-lg text-white">{step.description}</p>
                </Card>
              </motion.div>
            ))}
          </div>
          <h3 className="text-3xl font-bold text-white mb-4 text-center">Technology Stack</h3>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mb-12">
            {techStack.map((tech, index) => (
              <motion.div
                key={tech}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.1 * index, duration: 0.3 }}
                className="bg-white rounded-lg shadow-md p-4 text-center"
              >
                <h3 className="text-lg font-semibold text-[#0400ff]">{tech}</h3>
              </motion.div>
            ))}
          </div>
        </div>
      </main>
      
  
    </div>
  );
}

export default TsiwsAPIServicesCombined;
