import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

import { Card, CardContent } from './ui/card';
import { Zap, BarChart, Cog, Brain, SettingsIcon,CheckCircle, Shield, FileText, Heart, ShoppingCart, DollarSign, Factory, Truck, GraduationCap, Leaf, Home, Car } from 'lucide-react';
import AIIntegratedRetail from './AIIntegratedRetail';
import AIIntegratedSecurity from './AIIntegratedSecurity';
import AIIntegratedAgriculture from './AIIntegratedAgriculture';
import AIIntegrationImportance from './AIIntegrationImportance';
import logo from './assets/tsiws-logo.png';
import Footer from './Footer'; // Corrected import
import AiIntegrationSidebar from './AiIntegrationSidebar';
import AnimatedParticleConstellationBackground from './AnimatedParticleConstellationBackground';



function AIIntegration() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const industries = [
    { icon: <Heart className="w-12 h-12 text-[#0400ff]" />, title: "Healthcare", description: "AI-driven diagnostics and treatment planning" },
    { icon: <ShoppingCart className="w-12 h-12 text-[#0400ff]" />, title: "Retail", description: "Personalized shopping recommendations" },
    { icon: <DollarSign className="w-12 h-12 text-[#0400ff]" />, title: "Finance", description: "AI-based fraud detection" },
    { icon: <Factory className="w-12 h-12 text-[#0400ff]" />, title: "Manufacturing", description: "Predictive maintenance and defect detection" },
    { icon: <Truck className="w-12 h-12 text-[#0400ff]" />, title: "Logistics", description: "Route optimization and delivery tracking" },
    { icon: <GraduationCap className="w-12 h-12 text-[#0400ff]" />, title: "Education", description: "Adaptive learning platforms" },
    { icon: <Zap className="w-12 h-12 text-[#0400ff]" />, title: "Energy", description: "Smart grid management" },
    { icon: <Leaf className="w-12 h-12 text-[#0400ff]" />, title: "Agriculture", description: "Precision farming techniques" },
    { icon: <Home className="w-12 h-12 text-[#0400ff]" />, title: "Real Estate", description: "AI-powered property valuation" },
    { icon: <Car className="w-12 h-12 text-[#0400ff]" />, title: "Transportation", description: "Autonomous vehicles and traffic management" },
  ];

  return (
    <div className=" min-h-screen bg-gradient-to-b from-white to-[#0400ffa8]">
      <AnimatedParticleConstellationBackground />
      <motion.header
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="relative bg-gradient-to-r from-white to-[#0400ffa8] py-20 px-4 sm:px-6 lg:px-8 rounded-lg shadow-2xl overflow-hidden
         w-[96%] sm:w-[68%] mx-[2%] sm:ml-[30%] sm:mr-[1%] mt-[1%] sm:mt-[2%] mb-[5%] sm:mb-[2%] rounded-t-3xl"
      >
         
        <div className="bg-gradient-to-r from-white/20 to-[#0400ff30] relative max-w-6xl mx-auto text-center mr-2">
         
        <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mt-4 mb-6 px-4 py-6 rounded-2xl"
            style={{
                boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)"
            }}>
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="mb-10"
          >
        <img
              src={logo}
              alt="Tsiws Logo"
              width={150}
              height={150}
              className="mx-auto filter drop-shadow-lg -mt-8 sm:-mt-4"
            />
            
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.5 }}
            className="bg-gradient-to-r from-white/20 to-[#0400ff30] backdrop-filter backdrop-blur-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mt-8 mb-6 py-4 rounded-2xl w-90% mx-auto"
            style={{
              boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)"
            }}
          >
            <Brain className="w-12 h-12 text-[#0400ff] mx-auto mb-6" />
            <h1 className="text-5xl sm:text-5xl md:text-6xl font-extrabold mb-6 tracking-tight text-[#0400ff] -mt-6" style={{ textShadow: '1px 1px 10px white' }}>
              AI Systems Integration
            </h1>
          </motion.div>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6, duration: 0.5 }}
            className="text-xl sm:text-2xl md:text-3xl mb-4 font-bold text-white"
            style={{ textShadow: '1px 1px 10px #0400ff' }}
          >
           Your next upgrade!
          </motion.p>
         </div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 0.5 }}
            className="bg-white bg-opacity-40 backdrop-filter backdrop-blur-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mt-14 mb-10 rounded-2xl p-4 relative"
            style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)" }}
          >
            <p className="text-lg sm:text-xl md:text-2xl font-bold text-[#0400ff]" style={{ textShadow: '1px 1px 10px white' }}>
              Leverage the transformative capabilities of artificial intelligence by integrating it into your operations and infrastructure.
            </p>
               </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.2, duration: 0.5 }}
            style={{ textShadow: '1px 1px 10px white' }}
          >
           
          </motion.div>
        </div>
      </motion.header>

      <main className=" py-12 px-4 sm:px-6 lg:px-8  w-[96%] sm:w-[68%] mx-[2%] sm:ml-[30%] sm:mr-[1%] mt-[1%] sm:mt-[2%] mb-[5%] sm:mb-[2%] rounded-t-3xl">
         <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mt-4 mb-6 py-8 px-8 rounded-2xl"
            style={{
                boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)"
            }}>
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <Card className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg shadow-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mb-16">
              <CardContent className="p-8">
                <Card className="mb-4 py-6 px-6" style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)" }}>
                  <h2 className="text-3xl font-bold text-[#0400ff]">Harness the Advanced Capabilities of Artificial Intelligence</h2>
                </Card>
                <p className="text-lg text-gray-800">
                  Seamless Integration of AI Systems involves embedding artificial intelligence into various business operations and infrastructure. This enhances efficiency, decision-making, and innovation. By automating tasks, providing data-driven insights, and improving customer interactions, AI integration drives growth across industries.
                </p>
              </CardContent>
            </Card>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.5 }}
            className="mb-16"
          >
            <h3 className="text-3xl font-bold text-center mb-8 text-[#0400ff]">Industries and Applications</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {industries.map((industry, index) => (
                <IndustryCard key={index} icon={industry.icon} title={industry.title} description={industry.description} />
              ))}
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6, duration: 0.5 }}
          >
            <Card className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg shadow-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mb-8">
              <CardContent className="p-8">
                <h2 className="text-3xl font-bold text-[#0400ff] mb-4">AI Integrated Systems and Use Cases</h2>
                        <Card className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg shadow-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mb-8">
              <CardContent className="p-8">
                          <p className="text-lg text-gray-800">
                  Explore specific examples and methods of AI system integration across industries, offering a comprehensive view of its applications and impact. From healthcare to transportation, AI is revolutionizing how we approach challenges and create solutions.
                </p>
              </CardContent>
            </Card>
              </CardContent>
            </Card>
          </motion.div>
        
        </div>
        </div>
      </main>
 
      <AiIntegrationSidebar/>
      <AIIntegratedRetail/>
      <AIIntegratedSecurity/>
      <AIIntegratedAgriculture/>
      <AIIntegrationImportance/>
      <Footer/>
    </div>
  );
}

function IndustryCard({ icon, title, description }) {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Card className="h-full bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg shadow-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20">
        <CardContent className="p-6">
          <div className="flex items-center space-x-4 mb-4">
            {icon}
            <h3 className="text-xl font-bold text-[#0400ff]">{title}</h3>
          </div>
          <p className="text-gray-800">{description}</p>
        </CardContent>
      </Card>
    </motion.div>
  );
}

export default AIIntegration;
