import React from 'react';
import { motion } from 'framer-motion';
import { CheckCircle } from 'lucide-react';
import { Card, CardContent } from './ui/card';

function SmartWebsiteComponent() {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="flex justify-center items-center min-h-screen bg-gradient-to-br from-blue-500/50 via-blue-500/50 to-blue-600/50 p-4 sm:p-8 md:p-12 lg:p-16 mt-16 rounded-[20px]
               w-[96%] sm:w-[68%] 
    mx-[2%] sm:ml-[30%] sm:mr-[1%]
    mt-[1%] sm:mt-[2%] 
    mb-[5%] sm:mb-[2%]
    rounded-t-3xl backdrop-filter backdrop-blur-md"
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-3xl"
      >
        <div className="overflow-hidden bg-transparent rounded-[20px] shadow-2xl">
          <motion.div 
            className="flex flex-col items-center pt-8 pb-4 relative"
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold text-white text-center mb-2 text-shadow">Why You Need a Smart Website</h2>
            <p className="text-xl text-blue-100 text-center">Drive growth and innovation in the digital era</p>
          </motion.div>
          <motion.div 
            className="p-6 sm:p-8 md:p-10"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <div className="space-y-8">

              <div className="bg-white/20 shadow-custom hover:shadow-2xl transition-all duration-300 border border-white/10 mb-6 mt-1 rounded-2xl px-6 py-1">
                <p className="text-lg text-blue-100 ">
                  In today&apos;s fast-paced digital landscape, businesses must adapt and thrive like never before. Traditional websites are no longer sufficient. Enter smart websites—the next step in digital evolution. They deliver dynamic, interactive, and assistive experiences that go beyond just looking good.
                </p>
              </div>

              <motion.div
                whileHover={{ scale: 1.02 }}
                className="bg-blue-500/20 rounded-lg p-6 border border-blue-300/20"
              >
                <h3 className="text-2xl font-semibold text-white mb-4">Smart websites are powerful tools that:</h3>
                <ul className="space-y-2">
                  {["Drive engagement", "Streamline operations", "Deliver personalized experiences"].map((item, index) => (
                    <motion.li
                      key={index}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="flex items-center text-blue-100"
                    >
                      <CheckCircle className="w-5 h-5 mr-2 text-green-400" />
                      {item}
                    </motion.li>
                  ))}
                </ul>
              </motion.div>
              <div>
  <h3 className="text-2xl font-semibold text-white mb-4">Benefits of Smart Websites:</h3>
  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 rounded-[30px]">
    {[
      { title: "Improved Customer Service", icon: "" },
      { title: "Efficient Query Handling", icon: "" },
      { title: "24/7 Availability", icon: "" },
      { title: "Enhanced User Engagement", icon: "" },
      { title: "Personalized Shopping Experience", icon: "" },
      { title: "Cost-Effective Sales Support", icon: "" },
      { title: "Automated Routine Tasks", icon: "" },
      { title: "Increased Conversion Rates", icon: "" },
      { title: "Real-Time Notifications", icon: "" },
      { title: "Data-Driven Insights", icon: "" },
      { title: "Scalability for Business Growth", icon: "" },
      { title: "Faster Navigation & Accessibility", icon: "" }
    ].map((benefit, index) => (
      <motion.div
        key={index}
        whileHover={{ scale: 1.05 }}
        className="bg-blue-600/20 rounded-lg p-4 text-center border border-white/10"
      >
        <span className="text-4xl mb-2 block">{benefit.icon}</span>
        <h4 className="text-lg font-semibold text-white">{benefit.title}</h4>
      </motion.div>
    ))}
  </div>
</div>

              <div className="bg-white/10 shadow-custom hover:shadow-2xl transition-all duration-300 border border-white/10 mb-6 mt-12 rounded-2xl px-6 py-1">
                <p className="text-lg text-blue-100 ">
                  Imagine a website that does more than just showcase products. Picture one that assists customers in real-time with personalized recommendations, guides them through the purchasing process, and handles administrative tasks, giving employees time to focus on what truly matters—innovation and growth.
                </p>
              </div>

              <div className="bg-white/10 shadow-custom hover:shadow-2xl transition-all duration-300 border border-white/10 mb-6 mt-12 rounded-2xl px-6 py-1">
                <p className="text-xl font-semibold text-white text-center ">
                  Smart websites don&apos;t just enhance the customer experience; they also streamline operations and set businesses up for future success. In today&apos;s digital era, a smart website is not an option—it&apos;s a necessity.
                </p>
              </div>
            </div>
          </motion.div> 
        </div>
      </motion.div>
    </motion.div>
  );
}

export default SmartWebsiteComponent;
