import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Card, CardContent } from "./ui/card";
import { motion } from 'framer-motion';
import logo from './assets/tsiws-logo.png';
import { ChevronRight, Bot, Briefcase, Brain, Zap, Shield, Coins, HeadphonesIcon, LineChart, PackageSearch } from 'lucide-react';
import Footer from './Footer';
import AiAgentSidebar from './AiAgentSidebar';

// FeatureCard component
const FeatureCard = ({ icon: Icon, title, description }) => (
  <Card className="bg-white bg-opacity-80 backdrop-blur-md rounded-xl shadow-lg overflow-hidden
  w-[96%] sm:w-[68%] 
    mx-[2%] sm:ml-[30%] sm:mr-[1%]
    mt-[1%] sm:mt-[2%] 
    mb-[5%] sm:mb-[2%]
    rounded-t-3xl">

    <CardContent className="p-6">
      <Icon className="w-12 h-12 text-[#0400ff] mb-4" />
      <h3 className="text-xl font-semibold mb-2 text-[#0400ff]">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </CardContent>
  </Card>
);

// AIAgentCard component
const AIAgentCard = ({ icon: Icon, title, description, features }) => (
  <Card className="bg-white bg-opacity-90 backdrop-blur-md rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
    <CardContent className="p-6">
      <div className="flex items-center mb-4">
        <Icon className="w-8 h-8 text-[#0400ff] mr-3" />
        <h3 className="text-2xl font-semibold text-[#0400ff]">{title}</h3>
      </div>
      <p className="text-gray-700 mb-4">{description}</p>
      <ul className="space-y-2">
        {features.map((feature, index) => (
          <li key={index} className="flex items-start">
            <ChevronRight className="w-5 h-5 text-[#0400ff] mr-2 flex-shrink-0 mt-0.5" />
            <span className="text-gray-600">{feature}</span>
          </li>
        ))}
      </ul>
    </CardContent>
  </Card>
);

// Main function
export default function AIAgentDevelopment() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-gradient-to-r from-white/20 to-[#0400ff30] min-h-screen">
      <motion.header
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="relative py-20 px-4 sm:px-6 lg:px-8 rounded-lg shadow-2xl overflow-hidden
        w-[96%] sm:w-[68%] 
    mx-[2%] sm:ml-[30%] sm:mr-[1%]
    mt-[1%] sm:mt-[2%] 
    mb-[5%] sm:mb-[2%]
    rounded-t-3xl"
      >
        <div className="relative max-w-7xl mx-auto text-center">
        <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mt-2 mb-2 py-4 rounded-2xl"
            style={{
                boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)"
            }}>
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="mb-10"
          >
             <img
              src={logo}
              alt="Tsiws Logo"
              width={150}
              height={150}
              className="mx-auto filter drop-shadow-lg -mt-8 sm:-mt-4"
            />
          </motion.div>
  
          <div className="bg-white/30 backdrop-filter backdrop-blur-lg shadow-custom hover:shadow-2xl transition-all duration-300 border border-white/20 mt-0 mb-6 rounded-2xl px-6 py-8 flex flex-col items-center" style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)", width: "90%", margin: "0 auto" }}>
  <Bot className="text-[#0400ff] w-20 h-20 mb-8" /> {/* Centered Bot icon */}
  
  <motion.h1
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: 0.4, duration: 0.5 }}
    className="text-5xl sm:text-6xl md:text-7xl font-extrabold mb-6 tracking-tight text-[#0400ff] -mt-6 text-center"
    style={{ textShadow: '1px 1px 10px white' }}
  >
    AI Agents
  </motion.h1>
  
  <motion.p
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: 0.6, duration: 0.5 }}
    className="text-xl sm:text-2xl md:text-3xl mb-4 font-bold text-white text-center"
    style={{ textShadow: '1px 1px 10px #0400ff' }}
  >
    Revolutionize Your Business with Intelligent AI Agents
  </motion.p>
</div>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8, duration: 0.5 }}
            className="text-lg sm:text-xl md:text-2xl mb-0 mt-10 font-bold text-[#0400ff]"
          >
            Enhance your work force with software that takes on tasks and do them for you. <br/><br/>Empower Your Operations with Cutting-Edge AI Technology
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 0.5 }}
          >
      
          </motion.div>
        </div>
        <div className="flex justify-center mt-6">
      <Link to="/contact">
        <button className="bg-transparent border-2 border-[#0400ff] text-[#0400ff] font-bold py-2 px-4 rounded-lg hover:bg-[#0400ff] hover:text-white transition duration-300 ease-in-out">
          Get Started
        </button>
      </Link>
      </div>
    </div>
      </motion.header>

      <main className="w-[96%] sm:w-[68%] 
    mx-[2%] sm:ml-[30%] sm:mr-[1%]
    mt-[1%] sm:mt-[2%] 
    mb-[5%] sm:mb-[2%]
    rounded-t-3xl">
        {/* Why Integrate AI Agents Section */}
        <motion.section
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mb-20"
        >
          <Card className="bg-blue bg-opacity-20 backdrop-filter backdrop-blur-lg shadow-lg hover:shadow-2xl transition-all duration-300 border border-white-2 border-opacity-90 p-6 mb-8 mt-14" style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)" }}>
            <h2 className="text-3xl sm:text-4xl font-bold text-[#0400ff] text-center">
              Why Integrate AI Agents into Your Business?
            </h2>
          </Card>
          <div className="bg-opacity-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
            <FeatureCard icon={Bot} title="24/7 Availability" description="AI agents work round the clock, ensuring your business never sleeps and your tasks are always completed." />
            <FeatureCard icon={Briefcase} title="Increased Productivity" description="Automate routine tasks and free up your human workforce to focus on high-value, strategic activities." />
            <FeatureCard icon={Zap} title="Instant Response" description="Provide immediate answers to customer inquiries, improving satisfaction and engagement." />
            <FeatureCard icon={Shield} title="Consistent Quality" description="Deliver uniform, high-quality responses and services across all customer interactions." />
            <FeatureCard icon={Coins} title="Cost-Effective" description="Reduce operational costs while scaling your business capabilities and customer support." />
            <FeatureCard icon={Brain} title="Continuous Learning" description="AI agents improve over time, adapting to your business needs and customer preferences." />
          </div>
        </motion.section>

        {/* Our Agentic Solutions Section */}
        <motion.section
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="mb-10"
        >
          <Card className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg shadow-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 p-6 mb-8" style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)" }}>
            <h2 className="text-3xl sm:text-4xl font-bold text-[#0400ff] text-center">
             Our Agentic Solutions
            </h2>
          </Card>  
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <AIAgentCard icon={HeadphonesIcon} title="Customer Service Agents" description="Elevate your customer support with AI-powered assistance across multiple channels." features={["24/7 availability", "Multi-language support", "Seamless human escalation", "Personalized interactions"]} />
            <AIAgentCard icon={LineChart} title="Sales Assistants" description="Boost your sales performance with AI-driven sales support." features={["Automated lead qualification", "Product recommendations", "Real-time updates", "Guided sales funnel"]} />
            <AIAgentCard icon={Zap} title="Task Management Agents" description="Enhance your team's productivity with AI-powered task management solutions." features={["Automated task assignment", "Real-time tracking", "Calendar integration", "Customizable workflows"]} />
            <AIAgentCard icon={Brain} title="Autonomous Agents" description="Agents that operate independently, making decisions based on predefined criteria." features={["Self-management", "Learning capabilities", "Autonomous decision-making", "System integration"]} />
            <AIAgentCard icon={PackageSearch} title="Inventory Management Agents" description="Optimize your supply chain with AI-powered inventory agents." features={["Inventory tracking", "Stock replenishment", "Demand forecasting", "Order processing automation"]} />
          </div>
        </motion.section>

        {/* Call to Action Section */}
        <motion.section
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className="text-center py-10"
        >
          
          <div className="bg-white/30 backdrop-filter backdrop-blur-lg shadow-custom hover:shadow-2xl transition-all duration-300 border border-white-2/50 mt-0 mb-1 rounded-2xl px-6 py-8">
  <p className="text-2xl sm:text-3xl font-bold text-[#0400ff] mb-4">
    Ready to Transform Your Business with AI?
  </p>
  <Card className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg shadow-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 p-6 mb-8" style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)" }}>
    <h2 className="text-3xl sm:text-1xl font-thin text-[#0400ff] text-center">
      Discover Our Intelligent AI Solutions Tailored to Elevate Your Business
    </h2>
    <p className="text-lg text-gray-700 mt-4">
      Our suite of AI-driven solutions is designed to enhance every aspect of your business, from customer service to task management and beyond. Experience seamless integration, improved productivity, and transformative efficiency with our specialized AI agents.
    </p>
  </Card> 
</div>

        </motion.section>
      </main>
      <AiAgentSidebar/>
      <Footer />
    </div>
  );
}
