import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { Card, CardContent } from "./ui/card";
import { Leaf, Droplet, Bug, Tractor, Bird, TrendingUp, ChevronDown, ChevronUp } from "lucide-react";

const sections = [
  {
    id: "precision-farming",
    title: "Precision Farming",
    icon: React.createElement(Leaf, { className: "w-6 h-6" }),
    description: "AI-powered crop monitoring, soil analysis, and yield prediction.",
    details:
      "Utilizes AI algorithms to analyze satellite imagery, drone footage, and sensor data for optimized crop management. Enables targeted application of fertilizers and pesticides, reducing waste and environmental impact.",
  },
  {
    id: "smart-irrigation",
    title: "Smart Irrigation",
    icon: React.createElement(Droplet, { className: "w-6 h-6" }),
    description: "AI-controlled watering systems for optimal water usage.",
    details:
      "Integrates weather forecasts, soil moisture sensors, and crop water requirements to deliver precise irrigation. Reduces water consumption while improving crop yields and quality.",
  },
  {
    id: "pest-management",
    title: "Pest Management",
    icon: React.createElement(Bug, { className: "w-6 h-6" }),
    description: "Early detection and targeted treatment of pests and diseases.",
    details:
      "Uses computer vision and machine learning to identify pest infestations and plant diseases at early stages. Enables precise and timely interventions, minimizing crop losses and pesticide use.",
  },
  {
    id: "autonomous-equipment",
    title: "Autonomous Equipment",
    icon: React.createElement(Tractor, { className: "w-6 h-6" }),
    description: "Self-driving tractors and AI-powered harvesting robots.",
    details:
      "Employs GPS, computer vision, and AI to automate farming operations. Increases efficiency, reduces labor costs, and allows for 24/7 operations during critical farming periods.",
  },
  {
    id: "livestock-management",
    title: "Livestock Management",
    icon: React.createElement(Bird, { className: "w-6 h-6" }),
    description: "AI-powered health monitoring and automated feeding systems.",
    details:
      "Utilizes wearable sensors and AI algorithms to monitor animal health, detect diseases early, and optimize feeding schedules. Improves animal welfare and increases productivity.",
  },
  {
    id: "market-insights",
    title: "Market Insights",
    icon: React.createElement(TrendingUp, { className: "w-6 h-6" }),
    description: "AI-driven price prediction and consumer trend analysis.",
    details:
      "Analyzes market data, weather patterns, and consumer behavior to predict crop prices and demand. Helps farmers make informed decisions on crop selection, timing of sales, and marketing strategies.",
  },
];

function AIIntegratedAgriculture() {
  const [expandedSection, setExpandedSection] = useState(null);

  const toggleSection = (id) => {
    setExpandedSection((prevId) => (prevId === id ? null : id));
  };

  return (
    React.createElement(motion.div, {
      initial: { opacity: 0, y: 20 },
      animate: { opacity: 1, y: 0 },
      transition: { duration: 0.5 },
      className: "w-[96%] sm:w-[68%] mx-[2%] sm:ml-[30%] sm:mr-[1%] mt-[1%] sm:mt-[2%] mb-[5%] sm:mb-[2%] rounded-t-3xl",
      style: { textShadow: "1px 1px 10px rgba(255, 255, 255, 1)" }
    },
    
      React.createElement(Card, {
        className: "bg-gradient-to-r from-white to-[#0400ffa8] shadow-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mb-16"
      },
        React.createElement(CardContent, { className: "p-8" },
          React.createElement(motion.h2, {
            initial: { opacity: 0, y: -20 },
            animate: { opacity: 1, y: 0 },
            transition: { duration: 0.5, delay: 0.2 },
            className: "text-4xl font-bold text-[#0400ff] mb-6 text-center"
          }, "AI Systems Integration in Agriculture"),
          React.createElement("p", {
            className: "text-lg text-[#0400ff] mb-8 text-center"
          }, "Revolutionizing farming through AI-powered analytics and automation."),
          React.createElement("div", { className: "grid grid-cols-1 md:grid-cols-2 gap-6" },
            sections.map((section, index) =>
              React.createElement(motion.div, {
                key: section.id,
                initial: { opacity: 0, y: 20 },
                animate: { opacity: 1, y: 0 },
                transition: { duration: 0.5, delay: 0.3 + index * 0.1 },
                className: "bg-white/10 backdrop-filter backdrop-blur-sm rounded-lg overflow-hidden"
              },
                React.createElement("div", {
                  onClick: () => toggleSection(section.id),
                  className: "w-full p-6 text-left cursor-pointer focus:outline-none focus:ring-2 focus:ring-[#0400ff] focus:ring-opacity-50"
                },
                  React.createElement("div", { className: "flex items-center justify-between mb-4" },
                    React.createElement("div", { className: "flex items-center" },
                      section.icon,
                      React.createElement("h3", { className: "text-xl font-semibold text-[#0400ff] ml-3" }, section.title)
                    ),
                    expandedSection === section.id
                      ? React.createElement(ChevronUp, { className: "w-5 h-5 text-[#0400ff]" })
                      : React.createElement(ChevronDown, { className: "w-5 h-5 text-[#0400ff]" })
                  ),
                  React.createElement("p", { className: "text-[#0400ff]" }, section.description)
                ),
                React.createElement(AnimatePresence, null,
                  expandedSection === section.id &&
                  React.createElement(motion.div, {
                    initial: { opacity: 0, height: 0 },
                    animate: { opacity: 1, height: "auto" },
                    exit: { opacity: 0, height: 0 },
                    transition: { duration: 0.3 },
                    className: "px-6 pb-6"
                  },
                    React.createElement("p", { className: "text-blue-900 mt-4" }, section.details)
                  )
                )
              )
            )
          )
        )
      )
    )
  );
}

export default AIIntegratedAgriculture;
