import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

import logo from './assets/tsiws-logo.png';
import { Card, CardContent } from './ui/card';

import { Zap, BarChart, Cog, Briefcase, Users, CheckCircle, Shield, FileText } from 'lucide-react';


function ConsultationServices() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
 
    
    <div className="min-h-screen bg-opacity-10 backdrop-filter backdrop-blur-sm bg-gradient-to-b from-white to-[#0400ffa8]">
     
      <motion.header
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="relative bg-opacity-50  bg-gradient-to-r from-white to-[#0400ffa8] py-20 px-0 sm:px-2 lg:px-6 rounded-lg shadow-2xl overflow-hidden 
        py-6 px-4 sm:px-6 lg:px-8 w-[96%] sm:w-[68%] mx-auto sm:ml-[30%] sm:mr-[1%] mt-[1%] sm:mt-[2%] mb-[5%] sm:mb-[2%] rounded-t-3xl"
      >
        <div className="relative max-w-4xl mx-auto text-center ">
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="mb-10"
          >
             <img
              src={logo}
              alt="Tsiws Logo"
              width={150}
              height={150}
              className="mx-auto filter drop-shadow-lg -mt-8 sm:-mt-4"
            />
          </motion.div>
         
       

<motion.p
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ delay: 0.6, duration: 0.5 }}
  className="text-xl sm:text-2xl md:text-3xl mb-0 mt-5 font-bold text-white
            bg-white py-4 bg-opacity-10 backdrop-filter backdrop-blur-lg shadow-custom hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 rounded-2xl"
  style={{ textShadow: '1px 1px 10px #0400ff', boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)" }}
>
  {/* Icon centered at the top */}
  <div className="flex justify-center mb-8">
    <Briefcase className="text-[#0400ff] w-12 h-12" />
  </div>

  <motion.p
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ delay: 1, duration: 0.5 }}
  className="text-md sm:text-lg md:text-xl lg:text-2xl mt-5 mb-6 font-bold text-[#0400ff] bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg shadow-custom hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 py-6 px-4 sm:px-6 rounded-2xl mx-auto"
  style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)", maxWidth: "90%" }}
>
  <motion.h1
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: 0.4, duration: 0.5 }}
    className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-extrabold tracking-tight text-[#0400ff] -mt-2 mb-6"
    style={{ textShadow: '1px 1px 10px white' }}
  >
    Consultation
  </motion.h1>
</motion.p>


  Empowering Your Digital Future with Expert Technology Consultation
</motion.p>

<motion.p
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ delay: 0.8, duration: 0.5 }}
  className="text-lg sm:text-xl md:text-2xl mb-4 font-light text-[#0400ff]"
>
  {/* Add any additional content here */}
</motion.p>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 0.5 }}
            className="text-lg sm:text-xl md:text-2xl mt-5 mb-10 font-bold text-[#0400ff] bg-white bg-opacity-40 backdrop-filter backdrop-blur-lg shadow-custom hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mb-6 py-5 rounded-2xl"
            style={{ textShadow: '1px 1px 10px white', boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)" }}
          >
            TSIWS partners with businesses to simplify technology decisions, ensuring growth, efficiency, and security in a rapidly evolving digital world
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.2, duration: 0.5 }}
            style={{ textShadow: '1px 1px 10px white' }}
          />
       
        </div>
        <div className="flex justify-center mt-6">
      <a href="/contact" className="bg-transparent border-2 border-[#0400ff] text-[#0400ff] font-bold py-2 px-4 rounded-lg hover:bg-[#0400ff] hover:text-white transition duration-300 ease-in-out">
        Consult with us
      </a>
    </div>
      </motion.header>

      <main className="relative  bg-opacity-10 backdrop-filter backdrop-blur-sm bg-gradient-to-r from-white to-[#0400ffa8] mt-10 mb-10 py-10 px-0 sm:px-2 lg:px-6 rounded-lg shadow-2xl overflow-hidden 
        py-12 px-4 sm:px-6 lg:px-8 w-[96%] sm:w-[68%] mx-[2%] sm:ml-[30%] sm:mr-[1%] mt-[1%] sm:mt-[2%] mb-[5%] sm:mb-[2%] rounded-t-3xl">
        <div className="max-w-7xl mx-auto py-2">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
         <Card className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg shadow-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mb-6 relative">
  <div className="absolute -top-10 left-1/2 transform -translate-x-1/2">
    <Users className="w-10 h-10 text-[#0400ff] mb-10" />
  </div>
  <CardContent className="p-8 pt-10">
    <h2 className="text-3xl font-bold text-[#0400ff] mb-4">Technology Consultation Services</h2>
    <p className="text-lg text-gray-800">
      Tsiws helps businesses navigate the complexities of today's digital landscape, offering comprehensive technology consultation services.
    </p>
  </CardContent>
</Card>

          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
            <ServiceCard
              icon={<Zap className="w-12 h-12 text-[#0400ff]" />}
              title="Technology Adoption"
              description="Assess business needs and identify suitable technologies for a competitive edge."
            />
            <ServiceCard
              icon={<BarChart className="w-12 h-12 text-[#0400ff]" />}
              title="Strategic Planning"
              description="Develop technology strategies aligned with business goals to drive success and growth."
            />
            <ServiceCard
              icon={<Cog className="w-12 h-12 text-[#0400ff]" />}
              title="Digital Transformation"
              description="Assist businesses in adopting digital technologies to improve efficiency and market competitiveness."
            />
            <ServiceCard
              icon={<CheckCircle className="w-12 h-12 text-[#0400ff]" />}
              title="IT Infrastructure Optimization"
              description="Analyze and optimize existing IT infrastructure to enhance performance and reduce costs."
            />
            <ServiceCard
              icon={<Shield className="w-12 h-12 text-[#0400ff]" />}
              title="Security Consulting"
              description="Protect businesses from cyber threats by assessing security needs and implementing safeguards."
            />
            <ServiceCard
              icon={<FileText className="w-12 h-12 text-[#0400ff]" />}
              title="Compliance and Risk Management"
              description="Help businesses comply with regulations and implement risk management strategies."
            />
          </div>

          <motion.div
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ delay: 0.4, duration: 0.5 }}
  className="mb-16"
>
<Card className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg shadow-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mb-6 p-8">
  <h3 className="text-3xl font-bold text-center mb-8 text-[#0400ff]">Our Consultation Process</h3>
  <div className="flex flex-wrap justify-center gap-4">
    {[
      'Discovery Phase', 
      'Assessment Phase', 
      'Strategy Development', 
      'Implementation Planning', 
      'Implementation', 
      'Monitoring & Evaluation', 
      'Optimization'
    ].map((phase, index) => (
      <motion.div
        key={phase}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.1 * index, duration: 0.3 }}
        className="relative bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg shadow-md hover:shadow-lg transition-all duration-300 rounded-full px-6 py-3 flex flex-col items-center"
      >
        {/* Step Number */}
        <div className="absolute -top-3 -left-3 w-8 h-8 bg-[#0400ff] text-white rounded-full flex items-center justify-center text-sm font-bold">
          {index + 1}
        </div>

        <span className="text-[#0400ff] font-semibold">{phase}</span>
      </motion.div>
    ))}
  </div>
  
</Card>
<div className="bg-white/30 backdrop-filter backdrop-blur-lg shadow-custom hover:shadow-2xl transition-all duration-300 border border-white/20 mt-0 mb-0 rounded-2xl px-6 py-8"
    style={{
        boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)"
    }}
>
  <p className="text-lg font-semibold text-center text-[#0400ff]">
    It is more important now more than ever to integrate with technology. <br />
    Schedule a consultation with us today.
  </p>
</div>

</motion.div>


          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6, duration: 0.5 }}
            className="text-center"
          >
        
          </motion.div>
        </div>
      </main>
  
   
    </div>
  );
}

function ServiceCard({ icon, title, description }) {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Card className="h-full bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg shadow-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20">
        <CardContent className="p-6">
          <div className="flex items-center space-x-4 mb-4">
            {icon}
            <h3 className="text-xl font-bold text-[#0400ff]">{title}</h3>
          </div>
          <p className="text-gray-800">{description}</p>
        </CardContent>
      </Card>
    </motion.div>
  );
}

export default ConsultationServices;
