export const Card = ({ children, className }) => (
  <div className={`border rounded-lg shadow-md ${className}`}>
    {children}
  </div>
);

export const CardHeader = ({ children }) => (
  <div className="p-4 border-b">
    {children}
  </div>
);

export const CardTitle = ({ children }) => (
  <h2 className="text-xl font-bold">{children}</h2>
);

export const CardDescription = ({ children }) => (
  <p className="text-sm text-gray-500">{children}</p>
);

export const CardContent = ({ children }) => (
  <div className="p-4">{children}</div>
);

export const CardFooter = ({ children }) => (
  <div className="p-4 border-t flex justify-between">{children}</div>
);

// Other components like Input, Label, Select, Button, etc.
