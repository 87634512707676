import React from 'react';
import { motion } from 'framer-motion';
import TaskAutomationSidebar from './TaskAutomationSidebar'; // Replace with your actual sidebar component
import Footer from './Footer';
import AnimatedParticleConstellationBackground2 from './AnimatedParticleConstellationBackground2'; 
import TaskAutomationContent from './TaskAutomationContent'; // Replace with your actual main content component
import TaskAutomationInfoCard1 from './TaskAutomationInfoCard1'

function TaskAutomationLayout() {
  return (
    <div className="relative flex flex-col items-center min-h-screen bg-transparent">
      
      {/* Background Graphics */}
      <div className="absolute inset-0 -z-10">
        <AnimatedParticleConstellationBackground2 />
      </div>
      
      {/* Main Content */}
      <TaskAutomationContent />
      <TaskAutomationSidebar />
      <TaskAutomationInfoCard1/>
      <Footer />
      
    </div>
  );
}

export default TaskAutomationLayout;
