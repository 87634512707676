import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faTwitter, faInstagram, faTiktok, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import logo from './assets/tsiws-logo.png';

// Replacing the Image component with a standard img tag for simplicity and clarity
const Image = ({ src, alt, width, height, className }) => (
  <img src={src} alt={alt} width={width} height={height} className={className} />
);

// Component for individual sections within the footer
const FooterSection = ({ title, links }) => (
  <div className="mb-8 md:mb-0">
    <div className="border-b border-blue-700 mb-2"></div>
    <h3 className="text-lg font-semibold mb-2 text-blue-300 pb-2 border-b border-blue-700">
      {title}
    </h3>
    <ul className="space-y-3">
      {links.map((link, index) => (
        <motion.li 
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: index * 0.1 }}
        >
          <Link to={link.href} className="text-gray-300 hover:text-white transition-colors duration-200 flex items-center group">
            <span className="mr-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">›</span>
            {link.name}
          </Link>
        </motion.li>
      ))}
    </ul>
  </div>
);

// Component for social media icons with hover and tap animations
const SocialIcon = ({ icon, href }) => (
  <motion.a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="text-gray-400 hover:text-white transition-colors duration-200"
    whileHover={{ scale: 1.2, rotate: 5 }}
    whileTap={{ scale: 0.9 }}
  >
    <FontAwesomeIcon icon={icon} className="h-5 w-5" />
  </motion.a>
);

// Main Footer component
const Footer = () => {
  return (
    <footer className="bg-gradient-to-br from-gray-900/80 to-blue-900/80 text-white py-16 shadow-2xl
      w-[96%] sm:w-[68%] 
      mx-[2%] sm:ml-[30%] sm:mr-[1%]
      mt-[1%] sm:mt-[2%] 
      mb-[5%] sm:mb-[2%]
      rounded-t-3xl"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8 lg:gap-12">
          
          {/* Logo and company description */}
          <div className="col-span-1 md:col-span-2 lg:col-span-2">
            <div className="flex flex-col items-center lg:items-start">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <img
                  src={logo}
                  alt="Tsiws Logo"
                  width={200}
                  height={200}
                  className="mx-auto filter drop-shadow-lg -mt-8 sm:-mt-4"
                />
              </motion.div>
              <motion.p 
                className="text-gray-300 mb-8 text-center lg:text-left text-lg leading-relaxed"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                Pioneering AI-Centric Solutions: Transforming Industries with Cutting-Edge Products and Services
              </motion.p>

              {/* Social media icons */}
              <div className="flex flex-col space-y-4 w-full">
                <motion.div 
                  className="flex space-x-6 justify-center lg:justify-start"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                >
                  <SocialIcon icon={faWhatsapp} href="https://wa.me/8765338692" />
                  <SocialIcon icon={faPhone} href="tel:+18765338692" />
                  <SocialIcon icon={faEnvelope} href="mailto:tsiws.official@gmail.com" />
                </motion.div>
                <motion.div 
                  className="flex space-x-6 justify-center lg:justify-start"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.6 }}
                >
                  <SocialIcon icon={faTwitter} href="https://x.com/tsiws_official" />
                  <SocialIcon icon={faInstagram} href="https://instagram.com/tsiws_official" />
                  <SocialIcon icon={faTiktok} href="https://tiktok.com/@tsiws_official" />
                  <SocialIcon icon={faFacebook} href="https://facebook.com/tsiws" />
                  <SocialIcon icon={faYoutube} href="https://youtube.com/@tsiws" />
                </motion.div>
              </div>
            </div>
          </div>

          {/* Service sections */}
          <FooterSection
            title="SERVICES"
            links={[
              { name: "AI Voice Agents", href: "/ai-agents" },
              { name: "AI Integration", href: "/ai-integration" },
              { name: "Smart Websites", href: "/smart-websites" },
              { name: "Task Automation", href: "/task-automation" },
              { name: "Technology Consultation", href: "/consultation" },
              { name: "App Development", href: "/app-development" },
              { name: "API Development", href: "/api-services" },
            ]}
          />

          {/* Company and Collaboration sections */}
          <div>
            <FooterSection
              title="COMPANY"
              links={[
                { name: "About Us", href: "/about" },
                { name: "Careers", href: "/careers" },
                { name: "Internships", href: "/internships" },
                { name: "Contact Us", href: "/contact" },
              ]}
            />
            <FooterSection
              title="COLLABORATE"
              links={[
                { name: "Partnerships", href: "/partnerships" },
                { name: "Research", href: "/research" },
                { name: "Development", href: "/development" },
              ]}
            />
          </div>

          {/* Resources and Support sections */}
          <div>
            <FooterSection
              title="RESOURCES"
              links={[
                { name: "Blog", href: "/blog" },
                { name: "Vlog", href: "/vlog" },
                { name: "FAQ", href: "/faq" },
                { name: "Documentation", href: "/docs" },
              ]}
            />
            <FooterSection
              title="SUPPORT"
              links={[
                { name: "Help Center", href: "/help" },
                { name: "Service Support", href: "mailto:support@tsiws.com" },
                { name: "Product Support", href: "mailto:support@tsiws.com" },
               
              ]}
            />
          </div>
        </div>

        {/* Footer bottom section with copyright and links */}
        <motion.div 
          className="mt-16 pt-8 border-t border-blue-700 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          <p className="text-gray-300 text-sm">
            © {new Date().getFullYear()} Tsiws. <br/> | All rights reserved. | <br/>
            <Link to="/privacy" className="ml-2 hover:text-white transition-colors duration-200">Privacy Policy</Link> | 
            <Link to="/terms" className="ml-2 hover:text-white transition-colors duration-200">Terms of Service</Link>
          </p>
        </motion.div>
      </div>
    </footer>
  );
};

export default Footer;
