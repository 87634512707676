import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingContainer from './components/LandingContainer';
import ContactLayout from './components/ContactLayout';
import AppsDevelopmentLayout from './components/AppsDevelopmentLayout';
import AIIntegration from './components/AIIntegration';
import SmartWebsite from './components/SmartWebsite';
import ApiDevelopmentLayout from './components/ApiDevelopmentLayout';

import AIAgentDevelopment from './components/AIAgentDevelopment';

import BlogHomePage from './components/BlogHomePage';
import TaskAutomationLayout from './components/TaskAutomationLayout'; // Ensure the import matches the file name
import ConsultationLayout from './components/ConsultationLayout';

import './index.css';
import './App.css';

function App() {
  const [showChat, setShowChat] = useState(false);

  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-gradient-to-br from-white via-[#0400ffa8] to-white font-montserrat">
        
        {/* Routes Definition */}
        <Routes>
          <Route path="/" element={<LandingContainer />} />
          <Route path="/contact" element={<ContactLayout />} /> 
         
          <Route path="/ai-integration" element={<AIIntegration />} /> 
          <Route path="/smart-websites" element={<SmartWebsite />} /> 
          <Route path="/app-development" element={<AppsDevelopmentLayout/>} /> 
          <Route path="/ai-agents" element={<AIAgentDevelopment />} /> 
          <Route path="/api-services" element={<ApiDevelopmentLayout />} />
          <Route path="/blog" element={<BlogHomePage />} /> 
          <Route path="/consultation" element={<ConsultationLayout/>} /> 
         
          <Route path="/task-automation" element={<TaskAutomationLayout />} /> {/* Updated route for Task Automation */}
          <Route path="*" element={<Navigate to="/" replace />} />         
        </Routes>

        {/* DialogBox and ChatInterface Components */}
       
      </div>
    </Router>
  );
}

export default App;
