import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import logo from './assets/tsiws-logo.png';
import Footer from './Footer';
import { Globe } from "lucide-react"; // Import the Code icon
import SmartWebsiteComponent from './SmartWebsiteComponent';
import SmartWebsiteCards from './SmartWebsiteCards';
import SmartWebsiteSidebar from './SmartWebsiteSidebar';
import AnimatedParticleConstellationBackground from './AnimatedParticleConstellationBackground'; // Updated path here
import SmartWebsiteInfoCard1 from './SmartWebsiteInfoCard1';
import SmartWebsiteInfoCard2 from './SmartWebsiteInfoCard2';

function SmartWebsite() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []); // Added useEffect here

  return (
    <>
      <AnimatedParticleConstellationBackground />

      {/* Main Smart Website Header Section */}
      <motion.header
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 0.85, y: 0 }}
        transition={{ duration: 0.5 }}
        className="relative bg-gradient-to-r from-white/20 to-[#0400ff30] py-20 px-4 sm:px-6 lg:px-8 rounded-lg shadow-2xl overflow-hidden
                   w-[96%] sm:w-[68%] 
                   mx-[2%] sm:ml-[30%] sm:mr-[1%]
                   mt-[1%] sm:mt-[2%] 
                   mb-[5%] sm:mb-[2%]
                   rounded-t-3xl backdrop-filter backdrop-blur-sm"
      >
        <div className="relative max-w-6xl mx-auto text-center">

  
  <motion.div
    initial={{ scale: 0.8, opacity: 0 }}
    animate={{ scale: 1, opacity: 1 }}
    transition={{ delay: 0.2, duration: 0.5 }}
    className="mb-10"
  >
    <img
      src={logo}
      alt="Tsiws Logo"
      width={150}
      height={150}
      className="mx-auto filter drop-shadow-lg -mt-8 sm:-mt-4"
    />
  </motion.div>
 
  <div className="bg-white/30 backdrop-filter backdrop-blur-lg shadow-custom hover:shadow-2xl transition-all duration-300 border border-white/20 mt-0 mb-6 rounded-2xl px-6 py-8">
  <div className="flex justify-center mb-4"> {/* Container for the icon */}
    <Globe size={48} color="#0400ff" className="drop-shadow-lg" /> {/* Code icon styling */}
  </div>
    <motion.h1
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.4, duration: 0.5 }}
      className="text-4xl sm:text-5xl md:text-6xl font-extrabold mb-6 tracking-tight text-[#0400ff]"
      style={{ textShadow: '1px 1px 10px white' }}
    >
      Smart Websites
    </motion.h1>
    
    <div className="bg-white/30 backdrop-filter backdrop-blur-lg shadow-custom hover:shadow-2xl transition-all duration-300 border border-white/20 mt-0 mb-6 rounded-2xl px-6 py-8" style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)" }}>
      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.6, duration: 0.5 }}
        className="text-xl sm:text-2xl md:text-3xl mb-1 font-bold text-white"
        style={{ textShadow: '1px 1px 10px #0400ff' }}
      >
        What exactly are they?
      </motion.p>
      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.8, duration: 0.5 }}
        className="text-lg sm:text-xl md:text-3xl mb-0 font-bold text-[#0400ff]"
      >
        Why do we really need them?
      </motion.p>
    </div>
  </div>

          <div className="bg-white/30 backdrop-filter backdrop-blur-lg shadow-custom hover:shadow-2xl transition-all duration-300 border border-white/20 mb-6 mt-12 rounded-2xl px-6 py-8" style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)" }}>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1, duration: 0.5 }}
              className="text-lg sm:text-xl md:text-2xl font-bold text-[#0400ff]"
              style={{ textShadow: '1px 1px 10px white' }}
            >
              A smart website is the modern standard in online interaction.
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1.2, duration: 0.5 }}
              className="text-lg sm:text-xl md:text-2xl mt-0 font-bold text-[#0400ff]"
              style={{ textShadow: '1px 1px 10px white' }}
            >
              Providing advanced capabilities that makes it more useful to you and more helpful to your users.
            </motion.p>
          </div>

          <a href="/contact" className="bg-[#0400ff] border-2 border-[#0400ff] text-white font-bold mt-5 py-3 px-6 rounded-lg hover:bg-transparent hover:text-[#0400ff] transition duration-300 ease-in-out">
            Get a smart website
          </a>
        </div>
      </motion.header>

      {/* Render SmartWebsiteInfoCard1 below the main header section */}
      <SmartWebsiteInfoCard1 />


      <SmartWebsiteSidebar/>
      <SmartWebsiteCards/>
      <SmartWebsiteComponent/>
      <SmartWebsiteInfoCard2 />
      <Footer/>
    </>
  );
}

export default SmartWebsite;
