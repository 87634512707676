import React from 'react'
import { motion } from 'framer-motion'

import { Card, CardContent } from './ui/card'
import { ArrowRight, Zap, Users, Lightbulb, Car, BarChart2, Workflow, Shield } from 'lucide-react'

function AIIntegrationImportance() {
  const benefits = [
    { icon: <Zap className="w-6 h-6" />, text: "Efficiency" },
    { icon: <Users className="w-6 h-6" />, text: "Customer Experience" },
    { icon: <Lightbulb className="w-6 h-6" />, text: "Innovation" },
  ]

  const impacts = [
    { icon: <BarChart2 className="w-6 h-6" />, text: "Decision-Making" },
    { icon: <Workflow className="w-6 h-6" />, text: "Operational Workflows" },
    { icon: <Car className="w-6 h-6" />, text: "Strategic Advantages" },
  ]

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="w-[96%] sm:w-[68%] mx-[2%] sm:ml-[30%] sm:mr-[1%] mt-[1%] sm:mt-[2%] mb-[5%] sm:mb-[2%] rounded-t-3xl"
>

      <Card className="bg-gradient-to-r from-white to-[#0400ffa8] shadow-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 overflow-hidden">
        <CardContent className="p-8">
          <motion.h2
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="text-4xl font-bold text-[#0400ff] mb-8 text-center"
            style={{ textShadow: '2px 2px 4px white' }}
          >
            Why AI System Integration Matters
          </motion.h2>

          <div className="flex flex-col lg:flex-row gap-8">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="flex-1"
            >
              <Card className="bg-white/10 backdrop-filter backdrop-blur-sm h-full">
                <CardContent className="p-6">
                  <h3 className="text-2xl font-semibold text-blue-600 mb-4">Necessity for Competitiveness</h3>
                  <p className="text-blue-700 mb-6">
                    AI integration is crucial for businesses to remain competitive in the digital era.
                  </p>

                  <h4 className="text-xl font-semibold text-blue-600 mb-3">Benefits of AI:</h4>
                  <ul className="space-y-2 mb-6">
                    {benefits.map((benefit, index) => (
                      <motion.li
                        key={index}
                        initial={{ opacity: 0, x: -10 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.3, delay: 0.6 + index * 0.1 }}
                        className="flex items-center text-blue-700"
                      >
                        {benefit.icon}
                        <span className="ml-2">{benefit.text}: AI enhances {benefit.text.toLowerCase()}.</span>
                      </motion.li>
                    ))}
                  </ul>

                  <h4 className="text-xl font-semibold text-blue-600 mb-3">Business Impact:</h4>
                  <ul className="space-y-2">
                    {impacts.map((impact, index) => (
                      <motion.li
                        key={index}
                        initial={{ opacity: 0, x: -10 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.3, delay: 0.9 + index * 0.1 }}
                        className="flex items-center text-blue-700"
                      >
                        {impact.icon}
                        <span className="ml-2">{impact.text}: AI improves {impact.text.toLowerCase()}.</span>
                      </motion.li>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
              className="flex-1"
            >
              <Card className="bg-white/10 backdrop-filter backdrop-blur-sm h-full">
                <CardContent className="p-6">
                  <h3 className="text-2xl font-semibold text-blue-600 mb-4">Future-Proofing</h3>
                  <p className="text-blue-700 mb-6">
                    AI solutions are not only for addressing current needs but also for preparing businesses for future challenges and opportunities.
                  </p>

                  <div className="flex items-center mb-6">
                    <Shield className="w-10 h-10 text-blue-600 mr-4" />
                    <p className="text-blue-700">
                      Protect your business against future disruptions and stay ahead of the competition with AI integration.
                    </p>
                  </div>

                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 1.2 }}
                    className="space-y-4"
                  >
                   
                  </motion.div>
                </CardContent>
              </Card>
            </motion.div>
          </div>
        </CardContent>
      </Card>
    </motion.div>
  )
}

export default AIIntegrationImportance;
