import React from 'react';
import { motion } from 'framer-motion';
import ApiServicesSidebar from './AiIntegrationSidebar';
import Footer from './Footer';
import AnimatedParticleConstellationBackground2 from './AnimatedParticleConstellationBackground2'; 
import TsiwsAPIServicesCombined from './TsiwsAPIServicesCombined';

function ApiDevelopmentLayout() {
  return (
    <div className="relative flex flex-col items-center min-h-screen bg-transparent">
      
      {/* Background Graphics */}
      <div className="absolute inset-0 -z-10">
        <AnimatedParticleConstellationBackground2 />
      </div>
      
      {/* Main Content */}
      <TsiwsAPIServicesCombined />
      <ApiServicesSidebar />
      <Footer />
      
    </div>
  );
}

export default ApiDevelopmentLayout;
