import React from 'react';
import { motion } from 'framer-motion';
import ContactCard from './ContactCard';  // Ensure the path is correct
import AdvancedContactCard from './AdvancedContactCard'; // Ensure the path is correct
import Footer from './Footer';
import ContactSidebar from './ContactSidebar';
import AnimatedParticleConstellationBackground3 from './AnimatedParticleConstellationBackground3'; // Up


function ContactLayout() {
  return (
    
    
    <div className="flex flex-col items-center">
       <AnimatedParticleConstellationBackground3/>
       <ContactSidebar />
      <ContactCard />           {/* Render the ContactCard component */}
      <AdvancedContactCard />   {/* Render the AdvancedContactCard component */}
      <Footer />                {/* Render the Footer component */}
    </div>
  );
}

export default ContactLayout;
