import React from 'react';
import { motion } from 'framer-motion';
import logo from './assets/tsiws-logo.png';


const BlogHomepage = () => {
  return (
    <div className="bg-gradient-to-r from-white to-[#0400ffa8] bg-opacity-50 w-full min-h-screen py-8 px-6 sm:px-12 md:px-20">
      
      {/* Featured Blog Section */}
      <motion.div
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-md shadow-custom rounded-3xl mb-10 p-8"
      >
        <img src={logo} alt="Featured Post" className="mx-auto w-32 h-32 mb-6" />
        <h2 className="text-3xl md:text-4xl font-extrabold text-[#0400ff] mb-4 text-center" style={{ textShadow: '1px 1px 10px white' }}>
          Featured Blog Title
        </h2>
        <p className="text-lg md:text-xl text-center text-white mb-6">
          A brief overview or highlight of the featured blog post. This space is perfect for a summary to catch the reader's attention.
        </p>
        <div className="flex justify-center">
          <button className="bg-transparent border-2 border-[#0400ff] text-[#0400ff] font-bold py-2 px-6 rounded-lg hover:bg-[#0400ff] hover:text-white transition duration-300">
            Read More
          </button>
        </div>
      </motion.div>

      {/* Blog Feed Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {[1, 2, 3, 4].map((post, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 * index, duration: 0.5 }}
            className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg shadow-custom rounded-xl p-6 transition-transform transform hover:-translate-y-2"
          >
            <div className="mb-4">
              <img
                src={logo}
                alt={`Blog Post ${post}`}
                className="w-full h-32 object-cover rounded-lg"
              />
            </div>
            <h3 className="text-2xl font-bold text-[#0400ff] mb-2" style={{ textShadow: '1px 1px 5px white' }}>
              Blog Post Title {post}
            </h3>
            <p className="text-white mb-4">
              A short summary of the blog post. This is the preview text that gives a glimpse into the content...
            </p>
            <button className="bg-transparent border-2 border-[#0400ff] text-[#0400ff] font-bold py-1 px-4 rounded-lg hover:bg-[#0400ff] hover:text-white transition duration-300">
              Read More
            </button>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default BlogHomepage;
