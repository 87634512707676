import React, { useState } from 'react';
import { Home, Globe, Code, Briefcase, Smartphone, Cog, Brain, MessageSquare, BookOpen, Mic, Menu, X } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faTwitter, faInstagram, faTiktok, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const menuItems = [
  { name: 'Home', icon: Home, href: '/' }, // Updated item to Home
  { name: 'AI Agents', icon: Mic, href: '/ai-agents' },
  { name: 'AI Integrations', icon: Brain, href: '/ai-integration' },
  { name: 'Smart Websites', icon: Globe, href: '/smart-websites' }, 
  { name: 'AI Task Automation', icon: Cog, href: '/task-automation' },
  { name: 'Tech Consultation', icon: Briefcase, href: '/consultation' },
 
  { name: 'API Development', icon: Code, href: '/api-services' },
  { name: 'Contact Us', icon: MessageSquare, href: '/contact' },
  { name: 'Blog', icon: BookOpen, href: '/blog' },
];

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Hamburger menu for mobile */}
      <button
        className={`fixed top-6 left-4 z-50 p-2 bg-blue-900 text-white rounded-md lg:hidden transition-all duration-300 ${isOpen ? 'hidden' : 'block'}`}
        onClick={toggleSidebar}
      >
        <Menu size={24} />
      </button>

      {/* Close button for mobile */}
      {isOpen && (
        <button
          className="fixed top-6 right-4 z-50 p-2 bg-blue-900 text-white rounded-md"
          onClick={toggleSidebar}
        >
          <X size={24} />
        </button>
      )}

      {/* Overlay for mobile when sidebar is open */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
          onClick={toggleSidebar}
        ></div>
      )}

      {/* Sidebar */}
      <div className={`fixed inset-y-0 left-2 z-50 w-64 lg:w-80 transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0 mt-6`}>
        <aside className="h-full bg-gradient-to-br from-blue-900 via-[#0400ffa8] to-blue-900 text-white rounded-lg shadow-lg overflow-y-auto relative">
          {/* Transparent overlay for mobile */}
          <div className="absolute inset-x-0 bottom-0 h-1/4 bg-gradient-to-b from-transparent to-white/20 pointer-events-none lg:hidden"></div>
          
          {/* Sidebar content */}
          <div className="p-6 space-y-6">
            <div className="font-montserrat font-bold text-3xl mb-8 text-center">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-300">
                Tsiws
              </span>
            </div>
            <nav className="space-y-1">
              {menuItems.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="flex items-center py-2 px-3 rounded-lg hover:bg-white/10 transition-all duration-200 group"
                  onClick={() => setIsOpen(false)}
                >
                  <item.icon className="h-5 w-5 mr-2 text-blue-300 group-hover:text-white transition-colors" />
                  <span className="font-montserrat text-sm font-semibold group-hover:translate-x-1 transition-transform">
                    {item.name}
                  </span>
                </a>
              ))}
            </nav>
            <div className="contact-section">
              <div className="flex space-x-4 contact-icons">
                <a href="https://wa.me/8765338692" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faWhatsapp} className="h-4 w-4" />
                </a>
                <a href="tel:+18765338692">
                  <FontAwesomeIcon icon={faPhone} className="h-4 w-4" />
                </a>
                <a href="mailto:tsiws.official@gmail.com">
                  <FontAwesomeIcon icon={faEnvelope} className="h-4 w-4" />
                </a>
              </div>
              <div className="flex space-x-4 social-icons">
                <a href="https://x.com/tsiws_official" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faTwitter} className="h-4 w-4" />
                </a>
                <a href="https://instagram.com/tsiws_official" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagram} className="h-4 w-4" />
                </a>
                <a href="https://tiktok.com/@tsiws_official" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faTiktok} className="h-4 w-4" />
                </a>
                <a href="https://facebook.com/tsiws" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFacebook} className="h-4 w-4" />
                </a>
                <a href="https://youtube.com/@tsiws" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faYoutube} className="h-4 w-4" />
                </a>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </>
  );
}

export default Sidebar;