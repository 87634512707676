import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import logo from './assets/tsiws-logo.png';
import Sidebar from './components/Sidebar';
import AnimatedParticleConstellationBackground from './components/AnimatedParticleConstellationBackground'; // Updated path here

function Header() {
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowBanner(false);
    }, 4000); // 4 seconds

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  // Get the current day
  const currentDay = new Date().toLocaleString('en-US', { weekday: 'long' });

  return (
    <>
      <Sidebar />
      
      {/* Background component */}
      <AnimatedParticleConstellationBackground />

      <motion.header
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="relative z-10 bg-opacity-30 bg-gradient-to-r from-white to-[#0400ffa8] rounded-lg shadow-custom overflow-hidden
                     w-[96%] sm:w-[68%] 
    mx-[2%] sm:ml-[30%] sm:mr-[1%]
    mt-[1%] sm:mt-[2%] 
    mb-[5%] sm:mb-[2%]
    rounded-t-3xl shadow-[0_10px_10px_rgba(4,0,255,0.5)]">
        
        <div className="relative mx-auto text-center py-10 sm:py-8 px-4 sm:px-6 lg:px-8">
        <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mt-8 mb-6 py-4 rounded-2xl"
            style={{
                boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)"
            }}>
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="mb-6 sm:mb-4"
          >
            <img
              src={logo}
              alt="Tsiws Logo"
              width={200}
              height={200}
              className="mx-auto filter drop-shadow-lg -mt-8 sm:-mt-4"
            />
          </motion.div>
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.5 }}
            className="text-8xl sm:text-6xl md:text-9xl font-extrabold mb-4 sm:mb-3 tracking-tight text-[#0400ff] -mt-6 sm:-mt-3"
            style={{ textShadow: '1px 1px 10px white' }}
          >
            Tsiws
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6, duration: 0.5 }}
            className="text-lg sm:text-xl md:text-2xl mb-3 sm:mb-2 font-bold text-white"
            style={{ textShadow: '1px 1px 10px #0400ff' }}
          >
            Technology Solutions Provider
          </motion.p>
          </div>
          {/* Banner Section */}
          {showBanner ? (
            <motion.p
              initial={{ opacity: 0, x: -100 }} // Start off-screen to the left
              animate={{ opacity: 1, x: 0 }} // Slide in to the original position
              transition={{ duration: 0.9 }} // Animation duration
              className="text-xl sm:text-5xl md:text-4xl lg:text-5xl font-extrabold mb-3 sm:mb-8 text-[#0400ff] 
              bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg py-4 rounded-2xl"
            >
              <span className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-extrabold pulsate">
                Happy {currentDay}
              </span> <br/>
               Welcome to Tsiws
            </motion.p>
          ) : (
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8, duration: 0.5 }}
              className="text-xl sm:text-5xl md:text-4xl lg:text-5xl font-extrabold mb-3 sm:mb-8 text-[#0400ff] 
              bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mt-8 mb-6 py-4 rounded-2xl"
              style={{
                boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)"  // Adjust values to your liking
              }}
            >
              The Jamaica based, AI Native <br /> Tech Agency
            </motion.p>
          )}

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 0.5 }}
            className="text-base sm:text-lg md:text-xl mb-8 sm:mb-6 mt-14 font-bold text-[#0400ff]"
            style={{ textShadow: '1px 1px 10px white' }}
          >
            Developing AI-Centric Products and Services across Industries
          </motion.p>
          
          {/* Buttons Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.2, duration: 0.5 }}
            className="flex justify-center gap-4 mt-6"
          >
            <a href="/contact" className="bg-transparent border-2 border-[#0400ff] text-[#0400ff] font-bold py-2 px-4 rounded-lg hover:bg-[#0400ff] hover:text-white transition duration-300 ease-in-out shadow-[0_4px_10px_rgba(4,0,255,0.5)]">
              Speak with a Human
            </a>
            <button className="bg-transparent border-2 border-[#0400ff] text-[#0400ff] font-bold py-2 px-4 rounded-lg hover:bg-[#0400ff] hover:text-white transition duration-300 ease-in-out shadow-[0_4px_10px_rgba(4,0,255,0.5)]">
              Speak with our AI
            </button>
          </motion.div>
          
        </div>
      </motion.header>

      {/* CSS for pulsating effect */}
      <style jsx>{`
        .pulsate {
          color: gold; /* Change text color to gold */
          animation: pulsate 1.5s infinite; /* Pulsate animation */
        }

        @keyframes pulsate {
          0% {
            transform: scale(0.5);
          }
          50% {
            transform: scale(1.5);
          }
          100% {
            transform: scale(1);
          }
        }
      `}</style>
    </>
  );
}

export default Header;
