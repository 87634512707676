import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { Card, CardContent } from './ui/card';
import { Shield, Eye, Zap, Brain, Lock, Search, UserCheck, Cog } from 'lucide-react';
import { cn } from '../lib/utils';

const sections = [
  {
    id: "threat-detection",
    title: "Threat Detection and Intelligence",
    icon: <Eye className="w-6 h-6" />,
    content: [
      "AI-Powered Threat Detection for real-time monitoring",
      "Behavioral Analytics to establish normal baselines",
      "Automated Threat Intelligence Integration"
    ]
  },
  {
    id: "incident-response",
    title: "Incident Response Automation",
    icon: <Zap className="w-6 h-6" />,
    content: [
      "AI-Based Incident Triage for alert prioritization",
      "Automated Playbooks for incident response workflows",
      "Continuous refinement of response strategies"
    ]
  },
  {
    id: "augmented-personnel",
    title: "AI-Augmented Security Personnel",
    icon: <Brain className="w-6 h-6" />,
    content: [
      "AI-powered surveillance and monitoring systems",
      "Smart communication tools with NLP capabilities",
      "Augmented Reality (AR) support for enhanced situational awareness",
      "AI-driven training and simulation modules"
    ]
  },
  {
    id: "predictive-analytics",
    title: "Predictive Security Analytics",
    icon: <Search className="w-6 h-6" />,
    content: [
      "Machine learning for forecasting potential threats",
      "Risk scoring and prioritization of assets and vulnerabilities",
      "Proactive resource allocation for threat mitigation"
    ]
  },
  {
    id: "insider-threats",
    title: "Insider Threat Detection",
    icon: <UserCheck className="w-6 h-6" />,
    content: [
      "Behavioral analytics for monitoring user activities",
      "Anomaly detection in access patterns and data usage",
      "Identification of potential malicious intent or security lapses"
    ]
  },
  {
    id: "compliance",
    title: "Compliance and Regulatory Alignment",
    icon: <Lock className="w-6 h-6" />,
    content: [
      "AI-powered compliance monitoring and audits",
      "Automated assessment of regulatory framework adherence",
      "Continuous alignment with industry standards and policies"
    ]
  }
];

function AIIntegratedSecurity() {
  const [expandedSections, setExpandedSections] = useState([]);
  const [hoveredSection, setHoveredSection] = useState(null);

  const toggleSection = (id) => {
    setExpandedSections(prev => 
      prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
    );
  };

  return (
    <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="w-[96%] sm:w-[68%] mx-[2%] sm:ml-[30%] sm:mr-[1%] mt-[1%] sm:mt-[2%] mb-[5%] sm:mb-[2%] rounded-t-3xl"
    style={{ textShadow: "1px 1px 10px rgba(255, 255, 255, 1)" }}
  >
  
      <Card className="bg-gradient-to-r from-white to-[#0400ffa8] shadow-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mb-16">
        <CardContent className="p-8">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <h2 className="text-4xl font-bold text-[#0400ff] mb-8 text-center flex items-center justify-center">
              <Shield className="w-10 h-10 mr-4" />
              AI Systems Integrated into Security
            </h2>
            <p className="text-lg text-[#0400ff] mb-8 text-center">
              Revolutionizing security operations through AI-powered analytics, automated incident response, and predictive threat prevention.
            </p>
          </motion.div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {sections.map((section) => (
              <motion.div
                key={section.id}
                initial={false}
                animate={{
                  backgroundColor: expandedSections.includes(section.id) ? "rgba(255, 255, 255, 0.1)" : "rgba(255, 255, 255, 0)",
                  scale: hoveredSection === section.id ? 1.02 : 1,
                  transition: { duration: 0.3 }
                }}
                onHoverStart={() => setHoveredSection(section.id)}
                onHoverEnd={() => setHoveredSection(null)}
                className={cn(
                  "border border-blue-600/20 rounded-lg overflow-hidden",
                  "transition-all duration-300 ease-in-out",
                  "hover:shadow-lg hover:border-blue-600/40"
                )}
              >
                <button
                  onClick={() => toggleSection(section.id)}
                  className="w-full p-4 flex justify-between items-center text-left focus:outline-none focus:ring-2 focus:ring-blue-600/50"
                >
                  <span className="text-lg font-medium text-[#0400ff] flex items-center">
                    {section.icon}
                    <span className="ml-2">{section.title}</span>
                  </span>
                  <motion.div
                    animate={{
                      rotate: expandedSections.includes(section.id) ? 180 : 0
                    }}
                    transition={{ duration: 0.3 }}
                  >
                    <Cog className="w-5 h-5 text-[#0400ff]" />
                  </motion.div>
                </button>
                <AnimatePresence initial={false}>
                  {expandedSections.includes(section.id) && (
                    <motion.div
                      initial="collapsed"
                      animate="open"
                      exit="collapsed"
                      variants={{
                        open: { opacity: 1, height: "auto" },
                        collapsed: { opacity: 0, height: 0 }
                      }}
                      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                      <div className="p-4 bg-white/10 backdrop-blur-sm">
                        <ul className="space-y-2">
                          {section.content.map((item, index) => (
                            <motion.li
                              key={index}
                              initial={{ opacity: 0, x: -20 }}
                              animate={{ opacity: 1, x: 0 }}
                              transition={{ delay: index * 0.1 }}
                              className="flex items-start"
                            >
                              <span className="mr-2 mt-1 text-blue-900">•</span>
                              <span className="text-black">{item}</span>
                            </motion.li>
                          ))}
                        </ul>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
          </div>
          
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="mt-12 text-center"
          >
       
          </motion.div>
        </CardContent>
      </Card>
    </motion.div>
  );
}

export default AIIntegratedSecurity;
