import React from 'react';
import { motion } from 'framer-motion';
import AppsdevelopmentSidebar from './AppDevelopmentSidebar';
import Footer from './Footer';
import AppsDevelopment from './AppDevelopment';
import AnimatedParticleConstellationBackground3 from './AnimatedParticleConstellationBackground3'; // Up


function AppsDevelopmentLayout() {
  return (
    <div className="relative flex flex-col items-center min-h-screen bg-transparent">
      
      {/* Background Graphics */}
      <div className="absolute inset-0 -z-10">
    
      </div>
      
      {/* Main Content */}
      <AnimatedParticleConstellationBackground3/>
      < AppsDevelopment/>
    < AppsdevelopmentSidebar/>
      <Footer />
      
    </div>
  );
}

export default AppsDevelopmentLayout;
