import React from 'react';
import { motion } from 'framer-motion';
import { Phone } from 'lucide-react'; // Import the Phone icon from Lucide
import logo from './assets/tsiws-logo.png';

function ContactCard() {
  return (
    <motion.header
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="relative bg-gradient-to-r from-white to-[#0400ffa8] rounded-lg shadow-2xl overflow-hidden
                 w-full sm:w-[68%] sm:ml-[30%] sm:mr-[1%] sm:mt-[2%] sm:mb-[2%]
                 mx-[2%] mt-[1%] mb-[5%]
                 min-h-[80vh] sm:min-h-[80vh] flex flex-col justify-center py-20 px-4 sm:px-6 lg:px-8"
      style={{ opacity: 0.5 }}  // Apply 50% transparency here
    >
      <div className="relative w-full mx-auto text-center">
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="mb-10"
        >
          <img
            src={logo}
            alt="Tsiws Logo"
            width={150}
            height={150}
            className="mx-auto filter drop-shadow-lg -mt-8 sm:-mt-4"
          />
        </motion.div>
        
        <div className="bg-white/30 backdrop-filter backdrop-blur-lg shadow-custom hover:shadow-2xl transition-all duration-300 border border-white/20 mt-0 mb-6 rounded-2xl px-6 py-8" style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)" }}>
          {/* Telephone Icon inside the card */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.5 }}
            className="flex justify-center mb-10"
          >
            <Phone className="text-[#0400ff]" size={60} />
          </motion.div>

          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.5 }}
            className="text-5xl sm:text-5xl md:text-7xl font-extrabold mb-6 tracking-tight text-[#0400ff] -mt-6"
            style={{ textShadow: '1px 1px 10px white' }}
          >
            Speak with us
          </motion.h1>
          
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6, duration: 0.5 }}
            className="text-xl sm:text-2xl md:text-3xl mb-4 mt-14 font-bold text-white"
            style={{ textShadow: '1px 1px 10px #0400ff' }}
          >
            Speak with a team member or feel free to get all of your quarries answered with our Ai Assistant.
          </motion.p>
        </div>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1, duration: 0.5 }}
          className="text-lg sm:text-xl md:text-2xl mb-1 mt-16 font-bold text-[#0400ff]"
          style={{ textShadow: '1px 1px 10px white' }}
        >
        There are no limits to our availability! <br/>  Reach out to us anytime, and we'll get back to you right away.
        </motion.p>

        <div className="w-full sm:w-[99%] lg:w-full mx-auto">
          {/* Additional content can be added here */}
        </div>
      </div>
    </motion.header>
  );
}

export default ContactCard;
