import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Card, CardContent } from "./ui/card"; // Ensure this path is correct

import { FaFacebook, FaTwitter, FaWhatsapp, FaLinkedin, FaInstagram, FaYoutube, FaEnvelope } from 'react-icons/fa6';

import { Phone, Mail, MapPin, Send, ChevronDown } from 'lucide-react';
import { Input } from './ui/input'; // Make sure this path is correct
import { Textarea } from './ui/textarea'; // Make sure this path is correct

// Define ContactItem component
const ContactItem = ({ icon: Icon, href }) => (
  <motion.a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center justify-center mb-4 text-[#0400ff] hover:text-blue-700 transition-colors duration-300"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <Icon className="w-8 h-8" />
  </motion.a>
);

// Define SocialIcon component
const SocialIcon = ({ icon: Icon, href }) => (
  <motion.a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="text-[#0400ff] hover:text-blue-700 transition-colors duration-300"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <Icon className="w-8 h-8" />
  </motion.a>
);

// Define FAQItem component
const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div
      className="mb-4 bg-white bg-opacity-50 rounded-lg shadow-md overflow-hidden"
      initial={false}
      animate={{ height: isOpen ? 'auto' : '60px' }}
      transition={{ duration: 0.3 }}
    >
      <motion.button
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between items-center w-full text-left text-[#0400ff] hover:text-blue-700 transition-colors duration-300 p-4"
        whileHover={{ scale: 1.02 }}
      >
        <span className="font-semibold">{question}</span>
        <ChevronDown
          className={`w-5 h-5 transition-transform duration-300 ${isOpen ? 'transform rotate-180' : ''}`}
        />
      </motion.button>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="px-4 pb-4 text-gray-600"
        >
          {answer}
        </motion.div>
      )}
    </motion.div>
  );
}

function AdvancedContactCard() {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="relative bg-gradient-to-r from-white to-[#0400ffa8] rounded-lg shadow-2xl overflow-hidden
                 w-full sm:w-[68%] sm:ml-[30%] sm:mr-[1%] sm:mt-[2%] sm:mb-[2%]
                 mx-[2%] mt-[1%] mb-[5%]
                 min-h-[80vh] sm:min-h-[80vh] flex flex-col justify-center"
    >
      <Card className="bg-transparent overflow-hidden rounded-3xl shadow-none opacity-70">
        <CardContent className="p-4 sm:p-6 md:p-8 lg:p-12">
          <motion.h2
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="text-4xl sm:text-5xl md:text-6xl font-bold mb-8 sm:mb-12 text-[#0400ff] text-center"
            style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.1)' }}
          >
            Tsiws
          </motion.h2>

          {/* Connect Now Section */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.4, duration: 0.5 }}
            className="bg-white bg-opacity-10 backdrop-blur-md p-4 sm:p-6 rounded-xl shadow-lg mb-8"
            style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)" }}
          >
            <h3 className="text-xl font-semibold mb-4 sm:mb-6 text-[#0400ff]">Connect Now</h3>
            <div className="flex justify-around">
              <ContactItem icon={Phone} href="tel:+18761234567" />
              <ContactItem icon={FaWhatsapp} href="https://wa.me/18761234567" />
              <ContactItem icon={Mail} href="mailto:contact@tsiws.com" />
              <ContactItem icon={MapPin} href="https://goo.gl/maps/Jamaica" />
            </div>
          </motion.div>

          {/* Follow Us Section */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.6, duration: 0.5 }}
            className="bg-white bg-opacity-10 backdrop-blur-md p-4 sm:p-6 rounded-xl shadow-lg mb-8"
            style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)" }}
          >
            <h3 className="text-xl font-semibold mb-4 sm:mb-6 text-[#0400ff]">Follow Us</h3>
        
            <div className="flex flex-wrap justify-between gap-4">
  <SocialIcon icon={FaFacebook} href="https://facebook.com/tsiws" />
  <SocialIcon icon={FaTwitter} href="https://twitter.com/tsiws" />
  <SocialIcon icon={FaLinkedin} href="https://linkedin.com/company/tsiws" />
  <SocialIcon icon={FaInstagram} href="https://instagram.com/tsiws" />
  <SocialIcon icon={FaYoutube} href="https://youtube.com/tsiws" />
  <SocialIcon icon={FaEnvelope} href="mailto:contact@tsiws.com" />
</div>

          </motion.div>

          {/* Send Us a Message Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8, duration: 0.5 }}
            className="bg-white bg-opacity-10 backdrop-blur-md p-4 sm:p-6 rounded-xl shadow-lg mb-8"
          >
            <h3 className="text-xl font-semibold mb-4 sm:mb-6 text-[#0400ff]">Send us a Message</h3>
            <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                <Input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="w-full bg-blue-50 border-[#0400ff] focus:border-blue-700"
                  required
                />
                <Input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full bg-blue-50 border-[#0400ff] focus:border-blue-700"
                  required
                />
              </div>
              <Textarea
                name="message"
                placeholder="Your Message"
                value={formData.message}
                onChange={handleInputChange}
                className="w-full bg-blue-50 border-[#0400ff] focus:border-blue-700"
                rows={6}
                required
              />
            </form>
          </motion.div>

          {/* Frequently Asked Questions Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.0, duration: 0.5 }}
            className="bg-white bg-opacity-10 backdrop-blur-md p-4 sm:p-6 rounded-xl shadow-lg mb-8"
            style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)" }}
          >
            <h3 className="text-xl font-semibold mb-4 sm:mb-6 text-[#0400ff]">Frequently Asked Questions</h3>
            <FAQItem 
              question="What services do you offer?" 
              answer="We offer a variety of services including web development, mobile application development, and digital marketing." 
            />
            <FAQItem 
              question="How can I contact you?" 
              answer="You can reach us through our contact form or via email at contact@tsiws.com." 
            />
            <FAQItem 
              question="What is your response time?" 
              answer="Our usual response time is within 24 hours on business days." 
            />
          </motion.div>

          {/* Location Map Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.0, duration: 0.5 }}
            className="bg-white bg-opacity-70 backdrop-blur-md p-4 sm:p-6 rounded-xl shadow-lg mt-8"
          >
            <h3 className="text-xl font-semibold mb-4 sm:mb-6 text-[#0400ff]">Location</h3>
            <div className="overflow-hidden rounded-lg shadow-lg">
              <iframe
                title="Map Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.7126009998374!2d-122.42615308468238!3d37.774929279759464!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085809f7b9c3cb1%3A0x29a4f4f9d76b69b!2sYour%20Location%20Name!5e0!3m2!1sen!2sus!4v1632988517091!5m2!1sen!2sus"
                width="100%"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              />
            </div>
          </motion.div>
          
        </CardContent>
      </Card>
      <div className="bg-white/30 backdrop-filter backdrop-blur-lg shadow-custom hover:shadow-2xl transition-all duration-300 border border-white/20 mt-10 mb-5 mx-2 rounded-2xl px-10 py-8 opacity-70" style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)" }}>
        <p className="text-blue-600 font-bold">Want to integrate advanced technology? Tsiws is here to do it with you!</p>
      </div>
    </motion.div>
  );
}

export default AdvancedContactCard;
