import React from 'react';
import Header from '../Header'; // Update the path to match the location
import ServiceSection from './ServiceSection'; // Adjust path here as well
import AboutSection from './AboutSection';
import Footer from './Footer';
import AnimatedParticleConstellationBackground from './AnimatedParticleConstellationBackground'; // Import the background component
import  LandingPageInfocard2 from './LandingpageInfoCard2';


function LandingContainer() {
  return (
    <div className="relative flex flex-col items-center">
      {/* Background component */}
      <AnimatedParticleConstellationBackground />

      {/* Foreground content */}
      <div className="relative z-5"> {/* Ensure the content is above the background */}
        <Header />
        <ServiceSection />
        <AboutSection />
        <LandingPageInfocard2/>
        <Footer />
      </div>
    </div>
  );
}

export default LandingContainer;
