import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { Card, CardContent } from './ui/card';
import { ShoppingCart, BarChart, Truck, Headphones, Zap, ChevronDown, ChevronUp } from 'lucide-react';

const sections = [
  {
    id: "inventory-tracking",
    title: "AI-Powered Real-Time Inventory Tracking",
    icon: React.createElement(ShoppingCart, { className: "w-6 h-6 text-primary" }),
    description: "Computer Vision Integration for shelf monitoring.",
    details: "Real-Time Inventory Updates linked with POS systems, Automatic Reordering based on set reorder points, Local Adaptation considering Jamaican consumer preferences."
  },
  {
    id: "demand-forecasting",
    title: "Predictive Analytics for Demand Forecasting",
    icon: React.createElement(BarChart, { className: "w-6 h-6 text-primary" }),
    description: "Analysis of local market trends and seasonal variations.",
    details: "Replenishment Optimization to minimize stockouts, Integration with Supplier Networks for efficient ordering."
  },
  {
    id: "route-optimization",
    title: "Delivery Route Optimization",
    icon: React.createElement(Truck, { className: "w-6 h-6 text-primary" }),
    description: "AI-powered route planning considering Jamaican traffic patterns.",
    details: "Last-Mile Delivery Efficiency optimization, Real-time integration of customer locations and preferences."
  },
  {
    id: "customer-service",
    title: "AI-Powered Customer Service",
    icon: React.createElement(Headphones, { className: "w-6 h-6 text-primary" }),
    description: "Multilingual AI chatbots supporting local dialects.",
    details: "24/7 availability for customer support, Natural Language Processing for understanding local nuances."
  },
  {
    id: "personalized-marketing",
    title: "Personalized Recommendations and Marketing",
    icon: React.createElement(Zap, { className: "w-6 h-6 text-primary" }),
    description: "AI-driven product recommendation engines.",
    details: "Content personalization reflecting Jamaican culture, Dynamic pricing and promotions based on local market insights."
  }
];

function AIIntegratedRetail() {
  const [expandedSection, setExpandedSection] = useState(null);

  const toggleSection = (id) => {
    setExpandedSection((prevId) => (prevId === id ? null : id));
  };

  return (
    React.createElement(motion.div, {
      initial: { opacity: 0, y: 20 },
      animate: { opacity: 1, y: 0 },
      transition: { duration: 0.5 },
      className: "w-[96%] sm:w-[68%] mx-[2%] sm:ml-[30%] sm:mr-[1%] mt-[1%] sm:mt-[2%] mb-[5%] sm:mb-[2%] rounded-t-3xl",
      style: { textShadow: "1px 1px 10px rgba(255, 255, 255, 1)" }
    },
    
      React.createElement(Card, {
        className: "bg-gradient-to-r from-white to-[#0400ffa8] shadow-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mb-16"
      },
        React.createElement(CardContent, { className: "p-8" },
          React.createElement(motion.h2, {
            initial: { opacity: 0, y: -20 },
            animate: { opacity: 1, y: 0 },
            transition: { duration: 0.5, delay: 0.2 },
            className: "text-4xl font-bold text-[#0400ff] mb-6 text-center",
            style: { textShadow: "1px 1px 10px rgba(255, 255, 255, 1)" }
          }, "AI Integrated Systems into Retail"),
          
          React.createElement("p", {
            className: "text-lg text-[#0400ff] mb-8 text-center"
          }, "Integrating AI systems into retail is extremely transformative, revolutionizing operations and customer interactions."),
          React.createElement("div", { className: "grid grid-cols-1 md:grid-cols-2 gap-6" },
            sections.map((section, index) =>
              React.createElement(motion.div, {
                key: section.id,
                initial: { opacity: 0, y: 20 },
                animate: { opacity: 1, y: 0 },
                transition: { duration: 0.5, delay: 0.3 + index * 0.1 },
                className: "bg-white/10 backdrop-filter backdrop-blur-sm rounded-lg overflow-hidden"
              },
                React.createElement("div", {
                  onClick: () => toggleSection(section.id),
                  className: "w-full p-6 text-left cursor-pointer focus:outline-none focus:ring-2 focus:ring-[#0400ff] focus:ring-opacity-50"
                },
                  React.createElement("div", { className: "flex items-center justify-between mb-4" },
                    React.createElement("div", { className: "flex items-center" },
                      section.icon,
                      React.createElement("h3", { className: "text-xl font-semibold text-[#0400ff] ml-3" }, section.title)
                    ),
                    expandedSection === section.id
                      ? React.createElement(ChevronUp, { className: "w-5 h-5 text-[#0400ff]" })
                      : React.createElement(ChevronDown, { className: "w-5 h-5 text-[#0400ff]" })
                  ),
                  React.createElement("p", { className: "text-[#0400ff]" }, section.description)
                ),
                React.createElement(AnimatePresence, null,
                  expandedSection === section.id &&
                  React.createElement(motion.div, {
                    initial: { opacity: 0, height: 0 },
                    animate: { opacity: 1, height: "auto" },
                    exit: { opacity: 0, height: 0 },
                    transition: { duration: 0.3 },
                    className: "px-6 pb-6"
                  },
                    React.createElement("p", { className: "text-blue-900 mt-4" }, section.details)
                  )
                )
              )
            )
          )
        )
      )
    )
  );
}

export default AIIntegratedRetail;
