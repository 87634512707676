

import React, { useEffect, useRef } from 'react'

export default function BinaryCodeRainBackground() {
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    const resizeCanvas = () => {
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
    }

    resizeCanvas()
    window.addEventListener('resize', resizeCanvas)

    const streams = []
    const streamCount = Math.floor(canvas.width / 20) // Adjust for density

    for (let i = 0; i < streamCount; i++) {
      streams.push({
        x: i * 20,
        y: Math.random() * canvas.height,
        speed: Math.random() * 1 + 0.5,
        fontSize: Math.floor(Math.random() * 6) + 8, // Font sizes between 8 and 14
        opacity: Math.random() * 0.5 + 0.1 // Opacity between 0.1 and 0.6
      })
    }

    const drawBinary = () => {
      ctx.fillStyle = 'rgba(0, 0, 50, 0.05)' // Very subtle blue tint for fade effect
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      streams.forEach(stream => {
        const binary = Math.random() > 0.5 ? '0' : '1'
        ctx.font = `${stream.fontSize}px monospace`
        ctx.fillStyle = `rgba(0, 100, 255, ${stream.opacity})`
        ctx.fillText(binary, stream.x, stream.y)

        stream.y += stream.speed
        if (stream.y > canvas.height) {
          stream.y = 0
          stream.x = Math.random() * canvas.width
        }
      })

      requestAnimationFrame(drawBinary)
    }

    drawBinary()

    return () => {
      window.removeEventListener('resize', resizeCanvas)
    }
  }, [])

  return (
    <div className="fixed inset-0 z-[-1] bg-blue-900 overflow-hidden">
      <canvas
        ref={canvasRef}
        className="absolute inset-0"
      />
    </div>
  )
}
