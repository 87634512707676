import React, { useState, useEffect } from 'react';

import logo from './assets/tsiws-logo.png';
import { Card, CardContent } from './ui/card'; // Update the path if necessary
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, Zap, BarChart, TrendingUp, CheckCircle, Clock, DollarSign, Repeat, Cpu, Users, 
  Building, ShoppingBag, Heart, Settings, Briefcase, HeadphonesIcon, Truck, Target, GraduationCap, Scale, UserCheck 
} from 'lucide-react';

const DropdownItem = ({ icon: Icon, title, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg shadow-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mb-4
    ">
      <CardContent className="p-4 ">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center justify-between w-full text-left"
        >
          <div className="flex items-center">
            <Icon className="w-8 h-8 text-[#0400ff] mr-6" />
            <h3 className="text-lg font-semibold text-[#0400ff]">{title}</h3>
          </div>
          <ChevronDown className={`w-5 h-5 text-[#0400ff] transition-transform duration-300 ${isOpen ? 'transform rotate-180' : ''}`} />
        </button>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="mt-4 text-gray-700 pl-14"
            >
              {description}
            </motion.div>
          )}
        </AnimatePresence>
      </CardContent>
    </Card>
  );
};

function TaskAutomationContent() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []); // Added useEffect here

  return (
    <div className=" bg-blue-500 bg-opacity-10 min-h-screen bg-gradient-to-br from-white to-[#0400ffa8]
     w-[96%] sm:w-[68%] 
      mx-[2%] sm:ml-[30%] sm:mr-[1%]
      mt-[1%] sm:mt-[2%] 
      mb-[5%] sm:mb-[2%]
      rounded-t-3xl">
    
    
    
    
    
    
    
    
    
    <motion.header
  initial={{ opacity: 0, y: -50 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ duration: 0.5 }}
  className="relative bg-gradient-to-r from-white to-[#0400ffa8] py-20 px-4 sm:px-6 lg:px-8 rounded-lg shadow-2xl overflow-hidden"
>
  <div className="relative max-w-6xl mx-auto text-center">
  <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mt-8 mb-6 py-4 rounded-2xl"
            style={{
                boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)"
            }}>
    <motion.div
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ delay: 0.2, duration: 0.5 }}
      className="mb-10"
    >
      <img
        src={logo}
        alt="Tsiws Logo"
        width={150}
        height={150}
        className="mx-auto filter drop-shadow-lg -mt-8 sm:-mt-4"
      />
    </motion.div>

    <div className="w-90% bg-white/10 backdrop-filter backdrop-blur-lg shadow-custom hover:shadow-2xl transition-all duration-300 border border-white/20 mt-0 mb-6 rounded-2xl px-4 sm:px- py-" style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)", margin: "0 auto" }}>
      <div className="flex justify-center mb-10">
        <Settings className="w-12 h-12 text-[#0400ff]" />
      </div>
      <motion.h1
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4, duration: 0.5 }}
        className="text-4xl sm:text-5xl md:text-6xl font-extrabold mb-6 tracking-tight text-[#0400ff] -mt-6"
        style={{ textShadow: '1px 1px 10px white' }}
      >
        Task Automation
      </motion.h1>
      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.6, duration: 0.5 }}
        className="text-lg sm:text-xl md:text-2xl mb-4 font-bold text-white"
        style={{ textShadow: '1px 1px 10px #0400ff' }}
      >
        Enhancing Productivity with Smart Automation Solutions
      </motion.p>
    </div>
</div>
    <motion.p
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.8, duration: 0.5 }}
      className="text-lg sm:text-xl md:text-2xl mb-4 mt-14 font-bold text-[#0400ff]"
    >
      <div className="w-full bg-white/30 backdrop-filter backdrop-blur-lg shadow-custom hover:shadow-2xl transition-all duration-300 border border-white/20 mt-0 mb-6 rounded-2xl px-4 sm:px-6 py-8" style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)" }}>
        Let AI handle your repetitive tasks so that you can focus more on the things that really matter
      </div>
    </motion.p>



          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.2, duration: 0.5 }}
            style={{ textShadow: '1px 1px 10px white' }}
          >
        
          </motion.div>
        </div>
      </motion.header>

      <main className="max-w-7xl mb-10 mx-auto px-4 sm:px-6 lg:px-8 py-16">
      
      <div className="bg-white bg-opacity-40 backdrop-filter backdrop-blur-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mt-8 mb-6 py-4 rounded-2xl"
            style={{
                boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)"
            }}>  <motion.section
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mb-20"
        >
          <Card className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg shadow-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20">
            <CardContent className="p-8">
              <h2 className="text-3xl sm:text-4xl font-bold text-[#0400ff] mb-8 text-center">What is Task Automation?</h2>
              <p className="text-lg text-gray-800 text-center">
                Task automation is the use of technology to perform repetitive, rule-based tasks without human intervention. It leverages software robots, artificial intelligence, and machine learning to streamline processes, reduce errors, and increase efficiency across various business operations.
              </p>
            </CardContent>
          </Card>
        </motion.section>

        <motion.section
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="mb-20"
        >
          <h2 className="text-3xl sm:text-4xl font-bold text-[#0400ff] mb-8 text-center">The Benefits of Task Automation</h2>
          <div className="space-y-4">
            <DropdownItem
              icon={Zap}
              title="Streamline Processes"
              description="Automation helps in streamlining complex business processes by removing bottlenecks and reducing manual interventions, leading to smoother operations."
            />
            <DropdownItem
              icon={BarChart}
              title="Optimize Operations"
              description="By automating routine tasks, businesses can optimize their operations, allocate resources more efficiently, and focus on strategic initiatives."
            />
            <DropdownItem
              icon={TrendingUp}
              title="Boost Productivity"
              description="Automation significantly increases productivity by allowing employees to focus on high-value tasks while machines handle repetitive work."
            />
            <DropdownItem
              icon={CheckCircle}
              title="Enhance Accuracy"
              description="Automated systems reduce human error, ensuring higher accuracy in data entry, calculations, and other routine tasks."
            />
            <DropdownItem
              icon={Clock}
              title="Time Savings"
              description="Automation saves considerable time by executing tasks faster than humans and operating 24/7 without breaks."
            />
            <DropdownItem
              icon={DollarSign}
              title="Cost Savings"
              description="While there may be initial investment, automation leads to long-term cost savings through increased efficiency and reduced labor costs."
            />
            <DropdownItem
              icon={Repeat}
              title="Improve Workflow"
              description="Automation creates smoother, more efficient workflows by reducing delays and ensuring consistent execution of tasks."
            />
            <DropdownItem
              icon={Cpu}
              title="Smart Automation"
              description="Modern automation solutions incorporate AI and machine learning, enabling systems to learn and improve over time."
            />
            <DropdownItem
              icon={Users}
              title="Enhanced Customer Experience"
              description="Automation can lead to faster response times, personalized interactions, and improved service delivery, enhancing overall customer satisfaction."
            />
          </div>
        </motion.section>

        <motion.section
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="mb-20"
        >
          <h2 className="text-3xl sm:text-4xl font-bold text-[#0400ff] mb-2 text-center">Industries Benefiting from Task Automation</h2>
          <div className="space-y-4">
            <DropdownItem
              icon={Building}
              title="Manufacturing"
              description="Automation in manufacturing improves efficiency, ensures quality control, and reduces production costs."
            />
            <DropdownItem
              icon={ShoppingBag}
              title="Retail"
              description="Automation streamlines inventory management, enhances customer service, and optimizes supply chain operations in retail."
            />
            <DropdownItem
              icon={Heart}
              title="Healthcare"
              description="In healthcare, automation aids in patient scheduling, billing, data management, and other critical processes."
            />
            <DropdownItem
              icon={Briefcase}
              title="Finance"
              description="Financial institutions use automation for transaction processing, fraud detection, and regulatory compliance."
            />
            <DropdownItem
              icon={HeadphonesIcon}
              title="Customer Service"
              description="Automation in customer service enables faster response times, personalized support, and improved customer satisfaction."
            />
            <DropdownItem
              icon={Truck}
              title="Logistics"
              description="Automation in logistics enhances route optimization, inventory management, and tracking, improving overall operational efficiency."
            />
            <DropdownItem
              icon={Target}
              title="Marketing"
              description="Marketing automation enables personalized campaigns, lead nurturing, and data-driven decision-making."
            />
            <DropdownItem
              icon={GraduationCap}
              title="Education"
              description="In education, automation simplifies administrative tasks, facilitates personalized learning, and improves data management."
            />
            <DropdownItem
              icon={Scale}
              title="Legal"
              description="Law firms benefit from automation in document management, contract analysis, and compliance monitoring."
            />
            <DropdownItem
              icon={UserCheck}
              title="Human Resources"
              description="HR automation enhances recruitment, onboarding, payroll processing, and employee data management."
            />
          </div>
        </motion.section>
        </div>
      </main>
    </div>
  );
}

export default TaskAutomationContent;
