import React from 'react';
import { motion } from 'framer-motion';
import AnimatedParticleConstellationBackground from './AnimatedParticleConstellationBackground';

import Footer from './Footer';
import ConsultationSidebar from './ConsultationSidebar';
import ConsultationServices from './ConsultationServices';


function ConsultationLayout() {
  return (
    <div className="relative flex flex-col items-center min-h-screen bg-transparent">
      
      {/* Background Graphics */}
      <div className="absolute inset-0 -z-10">
      <AnimatedParticleConstellationBackground />
      </div>
      
      {/* Main Content */}
      <ConsultationSidebar/>
      <ConsultationServices/>
      <Footer />
      
    </div>
  );
}

export default ConsultationLayout;
