import React, { useCallback, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { ChevronDown, ChevronUp, Globe, Briefcase, Mic } from "lucide-react";

// Assuming you have your own Card and CardContent components
// If not, you'll need to create these or use a different UI library
import { Card, CardContent } from "./ui/card";

const ParticleBackground = ({ color }) => {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  return (
    <Particles
      className="absolute inset-0"
      init={particlesInit}
      options={{
        particles: {
          number: { value: 20, density: { enable: true, value_area: 800 } },
          color: { value: color },
          shape: { type: "circle" },
          opacity: {
            value: 0.5,
            random: true,
            anim: { enable: true, speed: 1, opacity_min: 0.1, sync: false },
          },
          size: {
            value: 3,
            random: true,
            anim: { enable: false, speed: 40, size_min: 0.1, sync: false },
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: color,
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 2,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: { enable: false, rotateX: 600, rotateY: 1200 },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: { enable: true, mode: "repulse" },
            onclick: { enable: true, mode: "push" },
            resize: true,
          },
          modes: {
            grab: { distance: 400, line_linked: { opacity: 1 } },
            bubble: { distance: 400, size: 40, duration: 2, opacity: 8, speed: 3 },
            repulse: { distance: 200, duration: 0.4 },
            push: { particles_nb: 4 },
            remove: { particles_nb: 2 },
          },
        },
        retina_detect: true,
      }}
    />
  );
};

const ServiceCard = ({
  icon: Icon,
  title,
  subtitle1,
  subtitle2,
  description,
  particleColor,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div
  whileHover={{ scale: 1.03 }}
  transition={{ type: "spring", stiffness: 300 }}
  className="relative overflow-hidden rounded-2xl"
>
  <Card className="bg-gradient-to-br mb-14 from-blue-600/80 via-[#0400ff30] to-blue-900/20 opacity-80 shadow-xl hover:shadow-2xl transition-all duration-300 border-2 border-blue-400/50 backdrop-blur-sm">
    <CardContent className="p-8 relative z-10 bg-blue-800/20 backdrop-blur-md rounded-xl">
      <ParticleBackground color={particleColor} />
      <div className="relative z-20">
        <div className="flex items-center mb-6">
          <Icon className="h-12 w-12 text-blue-300 mr-4" />
          <h2 className="text-3xl font-bold text-white">{title}</h2>
        </div>
        <p className="text-lg text-blue-200 mb-6">{subtitle1}</p>
        <p className="text-lg text-blue-200 mb-8">{subtitle2}</p>

        <div className="bg-blue-800/20 rounded-xl p-6 backdrop-blur-sm">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center justify-between w-full text-left bg-blue-600/70 hover:bg-blue-500/70 transition-colors duration-300 p-4 rounded-lg"
            aria-expanded={isOpen}
            aria-controls="benefits-content"
          >
            <h3 className="text-2xl font-bold text-white">Range of Benefits</h3>
            {isOpen ? (
              <ChevronUp className="h-8 w-8 text-white" />
            ) : (
              <ChevronDown className="h-8 w-8 text-white" />
            )}
          </button>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                id="benefits-content"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                <div className="mt-4 space-y-4 text-white">
                  {description.map((item, index) => (
                    <p key={index} className="leading-relaxed">
                      {item}
                    </p>
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </CardContent>
  </Card>
</motion.div>
  );
};

function SmartWebsiteCards() {
  const services = [
    {
        icon: Globe,
        title: "In-built Administrative Assistant",
        subtitle1: "The In-built Administrative Assistant feature helps manage administrative tasks efficiently...",
        subtitle2: "", // Default subtitle
        description: [
          "Time-saving: Automates routine tasks, freeing employees to focus on more critical work.",
          "Efficiency: Streamlines operations and reduces errors.",
          "Organization: Keeps schedules and emails well-organized and accessible.",
          "Cost-effective: Reduces the need for additional administrative staff.",
          "Improved Productivity: Boosts overall productivity by managing administrative tasks.",
          "Enhanced Communication: Ensures timely responses and efficient coordination.",
        ],
        particleColor: "#60A5FA",
      },
      {
        icon: Globe,
        title: "In-built Customer Service Agent",
        subtitle1: "The Personalized, User-specific Content feature enhances both e-commerce and customer service...",
        subtitle2: "", // Default subtitle
        description: [
          "Personalization: Customizing the user experience based on individual preferences and addressing users by name...",
          "Engagement: Personalized interactions can increase user engagement...",
          "Retention: A personalized experience can help retain users...",
          "Brand Loyalty: Users are more likely to develop loyalty towards a brand...",
          "Efficiency: Tailored assistance in customer service scenarios...",
        ],
        particleColor: "#60A5FA",
      },
      {
        icon: Globe,
        title: "Two-Way Messaging",
        subtitle1: "Allows users to sign in and directly message with the website owner...",
        description: [
          "Direct Communication: Enhances user interaction with the owner.",
          "Improved Customer Service: Real-time messaging improves satisfaction.",
          "Engagement: Increases time spent on the site by users.",
          "Support: Users can get help or answers immediately."
        ],
        particleColor: "#60A5FA",
      },
      {
        icon: Briefcase,
        title: "Automated Outreach",
        subtitle1: "Uses Twilio for automated messaging and calling...",
        description: [
          "Efficient Outreach: Automates notifications and calls.",
          "Customization: Tailored messages based on instructions.",
          "Follow-Up: Supports user engagement and feedback."
        ],
        particleColor: "#60A5FA",
      },
      {
        icon: Globe,
        title: "Mobile Activity Notifications",
        subtitle1: "Provides real-time website activity notifications on the owner’s mobile...",
        description: [
          "Real-Time Updates: Keeps the owner informed of site activities instantly.",
          "Accessibility: Mobile alerts ensure the owner is always updated.",
          "User Engagement: Immediate response possible due to timely notifications."
        ],
        particleColor: "#60A5FA",
      },
      {
        icon: Globe,
        title: "Personalized, User-specific Content",
        subtitle1: "The Personalized, User-specific Content feature enhances both e-commerce and customer service...",
        subtitle2: "", // Default subtitle
        description: [
          "Personalization: Customizing the user experience based on individual preferences and addressing users by name...",
          "Engagement: Personalized interactions can increase user engagement...",
          "Retention: A personalized experience can help retain users...",
          "Brand Loyalty: Users are more likely to develop loyalty towards a brand...",
          "Efficiency: Tailored assistance in customer service scenarios...",
        ],
        particleColor: "#60A5FA",
      },
      {
        icon: Globe,
        title: "Ai blog Generator",
        subtitle1: "Enables AI-generated blog content with single-click publishing...",
        description: [
          "Automation: AI generates blog posts on specified topics.",
          "SEO Optimization: Content optimized for search engines.",
          "Efficiency: Reduces time spent on content creation.",
          "Consistency: Ensures regular content updates."
        ],
        particleColor: "#60A5FA",
      },
      {
        icon: Briefcase,
        title: "E-commerce Capabilities",
        subtitle1: "Provides comprehensive e-commerce features designed to boost online sales and enhance customer satisfaction...",
        subtitle2: "", // Optional second subtitle
        description: [
          "Seamless Shopping Experience: Offers an intuitive, AI-driven shopping interface that makes browsing, selecting, and purchasing products easy for users.",
          "AI-Powered Customer Support: Provides instant, 24/7 assistance to answer questions, address concerns, and guide users throughout the purchasing process, improving customer satisfaction.",
          "Enhanced Engagement: Proactively engages with potential buyers through personalized recommendations and real-time responses, encouraging product exploration and purchase.",
          "Cost-Effective Sales Support: Reduces staffing needs by automating customer service, order tracking, and FAQ responses, making it an efficient and economical choice for businesses.",
          "Virtual Sales Assistant: Acts as a digital sales representative by offering personalized product suggestions, helping customers make purchasing decisions, and supporting them from product discovery to checkout."
        ],
        particleColor: "#60A5FA",
      },
      
      {
        icon: Globe,
        title: "AI-Powered Analytics",
        subtitle1: "Provides users with AI-driven analytics for deeper insights...",
        description: [
          "Detailed Insights: AI analyzes user data for actionable insights.",
          "Optimization: Improves website performance based on analytics.",
          "User Behavior: Helps understand visitor trends and behaviors."
        ],
        particleColor: "#60A5FA",
      },
      {
        icon: Mic,
        title: "Voice-Enabled Interaction",
        subtitle1: "Supports voice-based interactions for hands-free browsing...",
        description: [
          "Accessibility: Assists users with disabilities.",
          "Convenience: Enables voice queries and site navigation.",
          "Efficiency: Streamlines interaction for routine tasks."
        ],
        particleColor: "#60A5FA",
      },
      {
        icon: Globe,
        title: "Modular, Client-Handled UI",
        subtitle1: "Allows modular customization of the website’s interface...",
        description: [
          "Easy Customization: Users can modify UI elements with AI assistance.",
          "Real-Time Updates: Changes are applied without extensive coding.",
          "Engagement: Personalized UI enhances user satisfaction."
        ],
        particleColor: "#60A5FA",
      },
     
      {
        icon: Globe,
        title: "Swift Navigation",
        subtitle1: "Enables phrase-based navigation for faster access to site pages...",
        description: [
          "Efficiency: Users reach pages quickly by typing phrases.",
          "User-Friendly: Reduces time spent searching for specific content.",
          "Enhanced Experience: Streamlines navigation for better usability."
        ],
        particleColor: "#60A5FA",
      }
    
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 bg-transparent border-1 border-[#0400ff] text-[#0400ff] font-bold py-2 px-4 rounded-lg  hover:text-white transition duration-300 ease-in-out
                   w-[96%] sm:w-[68%] 
                   mx-[2%] sm:ml-[30%] sm:mr-[1%]
                   mt-[1%] sm:mt-[2%] 
                   mb-[5%] sm:mb-[2%] 
                   rounded-t-3xl">
      {services.map((service, index) => (
        <ServiceCard key={index} {...service} />
      ))}
    </div>
  );
}

export default SmartWebsiteCards;