import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

import logo from './assets/tsiws-logo.png';

import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Lightbulb, Code, Users, LifeBuoy, Sparkles, Rocket, Zap } from 'lucide-react';

export default function AppDevelopment() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
   
   <div className="bg-gradient-to-r from-white/20 to-[#0400ff30] backdrop-filter backdrop-blur-sm min-h-screen">

     
      <motion.header
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-gradient-to-r from-white/20 to-[#0400ff30] py-20 px-4 sm:px-6 lg:px-8 rounded-lg shadow-2xl overflow-hidden
         w-[96%] sm:w-[68%] 
    mx-[2%] sm:ml-[30%] sm:mr-[1%]
    mt-[1%] sm:mt-[2%] 
    mb-[5%] sm:mb-[2%]
    rounded-t-3xl"
      >
        <div className="relative max-w-6xl mx-auto text-center">
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="mb-10"
          >
            <img
              src={logo}
              alt="Tsiws Logo"
              width={150}
              height={150}
              className="mx-auto filter drop-shadow-lg -mt-8 sm:-mt-4"
            />
          </motion.div>
          <div className="bg-gradient-to-r from-white/20 to-[#0400ff30] backdrop-filter backdrop-blur-lg shadow-custom hover:shadow-2xl transition-all duration-300 border border-white/20 mt-0 mb-6 rounded-2xl px-6 py-8" style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)" }}>
          <div className="bg-gradient-to-r from-white/20 to-[#0400ff30] backdrop-filter backdrop-blur-lg shadow-custom hover:shadow-2xl transition-all duration-300 border border-white/20 mt-0 mb-6 rounded-2xl px-0 py-8" style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)" }}>
      

            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 0.5 }}
              className="text-5xl sm:text-5xl md:text-7xl font-extrabold mb-6 tracking-tight text-[#0400ff] -mt-6"
              style={{ textShadow: '1px 1px 10px white' }}
            >
              App Development
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6, duration: 0.5 }}
              className="text-xl sm:text-2xl md:text-3xl mb-4 font-bold text-white"
              style={{ textShadow: '1px 1px 10px #0400ff' }}
            >
              Let us build that App for you
            </motion.p>
            </div>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1, duration: 0.5 }}
              className="text-lg sm:text-xl md:text-2xl mb-10 font-bold text-[#0400ff]"
              style={{ textShadow: '1px 1px 10px white' }}
            >
              Give your customers the digital functionalities that enhance their productive capabilities
            </motion.p>
          </div>
        </div>
      </motion.header>

      {/* Main Content Section */}
      <main className=" bg-gradient-to-r from-white/20 to-[#0400ff30] backdrop-filter backdrop-blur-sm w-[96%] sm:w-[68%] 
    mx-[2%] sm:ml-[30%] sm:mr-[1%]
    mt-[1%] sm:mt-[2%] 
    mb-[5%] sm:mb-[2%]
    rounded-t-3xl">
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <Card className="bg-gradient-to-r from-white/20 to-[#0400ff30] backdrop-filter backdrop-blur-lg shadow-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 mb-16">
              <CardContent className="p-8">
                <h2 className="text-3xl font-bold text-[#0400ff] mb-4">Bringing Your Ideas to Life</h2>
                <p className="text-lg text-gray-800 mb-4">
                  Transform your ideas into powerful mobile solutions with Tsiws. We specialize in creating cutting-edge mobile applications that drive business growth and deliver exceptional user experiences.
                </p>
              </CardContent>
            </Card>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
            <ServiceCard
              icon={<Lightbulb className="w-12 h-12 text-[#0400ff]" />}
              title="Innovative Solutions"
              description="We specialize in creating cutting-edge mobile applications that not only meet but exceed user expectations."
            />
            <ServiceCard
              icon={<Code className="w-12 h-12 text-[#0400ff]" />}
              title="Expert Development"
              description="Our team of experts specializes in developing high-quality mobile applications tailored to your unique business needs."
            />
            <ServiceCard
              icon={<Users className="w-12 h-12 text-[#0400ff]" />}
              title="User-Centric Design"
              description="We prioritize user experience, ensuring your app is intuitive and engaging."
            />
            <ServiceCard
              icon={<LifeBuoy className="w-12 h-12 text-[#0400ff]" />}
              title="Comprehensive Support"
              description="We offer comprehensive support throughout your app development journey."
            />
          </div>

          {/* Feature Section */}
          <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className=" bg-gradient-to-r from-white/20 to-[#0400ff30] backdrop-filter backdrop-blur-sm py-16 px-4 sm:px-6 lg:px-8 bg-gradient-to-r from-[#0400ff] to-[#4d7fff] text-white"
          >
            <motion.h2 
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              className="text-4xl md:text-5xl font-bold text-center mb-8"
            >
              Let us build that App for you
            </motion.h2>

            <div className="grid md:grid-cols-2 gap-8 items-center">
              <motion.div
                initial={{ x: -50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.5 }}
              >
                <img
                  src={logo}
                  alt="Tsiws Logo"
                  width={300}
                  height={300}
                  className="mx-auto filter brightness-0 invert"
                />
              </motion.div>

              <motion.div
                initial={{ x: 50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.5 }}
              >
                <Card className="bg-gradient-to-r from-white/20 to-[#0400ff30] backdrop-filter backdrop-blur-lg border-none" style={{ boxShadow: "10px 20px 30px rgba(4, 0, 255, 0.5)" }}>
                  <CardContent className="p-6">
                    <p className="text-lg mb-4">
                      At Tsiws, we excel in creating innovative and user-centric applications that deliver tangible business results.
                    </p>
                  </CardContent>
                </Card>
              </motion.div>
            </div>

            <motion.div 
              className="mt-12 flex flex-wrap justify-center gap-6"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.8, duration: 0.5 }}
            >
              <FeatureCard
                icon={<Sparkles className="w-8 h-8" />}
                title="Innovative Solutions"
                description="Cutting-edge technology to bring your ideas to life"
              />
              <FeatureCard
                icon={<Rocket className="w-8 h-8" />}
                title="Rapid Development"
                description="Swift turnaround without compromising quality"
              />
              <FeatureCard
                icon={<Zap className="w-8 h-8" />}
                title="Seamless Integration"
                description="Smooth integration with existing systems"
              />
            </motion.div>
          </motion.section>
        </div>
      </main>
  

    </div>
  );
}

function ServiceCard({ icon, title, description }) {
  return (
    <Card className=" bg-gradient-to-r from-white/20 to-[#0400ff30] backdrop-filter backdrop-blur-sm bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg shadow-lg hover:shadow-2xl transition-all duration-300 border border-white border-opacity-20 p-6">
      <CardHeader className="flex items-center">
        <div className="flex-shrink-0">{icon}</div>
        <div className="ml-4">
          <CardTitle className="text-xl font-bold text-[#0400ff]">{title}</CardTitle>
        </div>
      </CardHeader>
      <CardContent>
        <p className="text-lg font-bold text-gray-100">{description}</p>
      </CardContent>
    </Card>
  );
}

function FeatureCard({ icon, title, description }) {
  return (
    <Card className="bg-gradient-to-r from-white/20 to-[#0400ff30]backdrop-filter backdrop-blur-lg border-none p-6">
      <CardHeader className="flex items-center text-white">
        <div className="flex-shrink-0">{icon}</div>
        <div className="ml-4">
          <CardTitle className="text-lg font-bold text-white">{title}</CardTitle>
        </div>
      </CardHeader>
      <CardContent>
        <p className="text-base text-white">{description}</p>
      </CardContent>
    </Card>
  );
}
